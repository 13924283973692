import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-action',
    template: `
    <button title="Edit" (click)="edit($event);" class="btn btn-primary btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;">
        <i class="fas fa-pencil-alt"></i>
    </button>
    <button title="Delete" (click)="delete($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#d71920">
        <i class="far fa-trash-alt"></i>
    </button>
    <button title="Schedule" (click)="schedule($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:#F4C430">
        <i class="fas fa-calendar-alt"></i>
    </button>`
})

export class ReportTemplateButton implements ICellRendererAngularComp {

    params;
    label: string;

    agInit(params): void {
        this.params = params;
        this.label = this.params.label || null;
    }

    refresh(params?: any): boolean {
        return true;
    }

    edit($event) {
        if (this.params.edit instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data
                // ...something
            }
            this.params.edit(params);

        }
    }

    delete($event) {
        if (this.params.delete instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data
                // ...something
            }
            this.params.delete(params);

        }
    }

    schedule($event) {
        if (this.params.schedule instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowData: this.params.node.data
                // ...something
            }
            this.params.schedule(params);

        }
    }

}