<div class="topDiv" [style.background-color]="backgroundColorScreen">

  <div class="agGridOuterDiv" style="height: -webkit-fill-available">
    <div style="display: flex;">

      <div style="margin-top: 2.5%" class="col-lg-3">
      <div class="row" style="display: block;margin-right: 5px;">
        <div class="col-lg" style="padding:0;">
          <div [formGroup]="FormValid">
            <mat-toolbar class="agGridtoperDiv card" style="
                  background: rgb(228 227 230 / 41%);
                  border-top: 1px solid #a9a9a9a8 ;
                  border-left: 1px solid #a9a9a9a8 ;
                  border-right: 1px solid #a9a9a9a8 ;
                  margin-top: 0%;">
              <label i18n="@@spmsUserReg" class="textColour">Test Scripts</label>
              <span class="example-spacer"></span>
                <div class="selectRecordsDiv" style="width:49.5%">
                <mat-form-field  appearance="outline" style="width:100%">
                  <mat-label i18n="@@Targetrecords" style="color:black">Cycle Name</mat-label>

                  <mat-select formControlName="cycleName" [(ngModel)]="cycleId" (valueChange)="selectCycle($event)">
                    <mat-option *ngFor="let cycleName of cycleNameDropDown" [value]="cycleName.testcyclemstrid">
                      {{cycleName.cyclename}}</mat-option>



                  </mat-select>

                </mat-form-field>
              </div>

            </mat-toolbar>

          </div>
          <ag-grid-angular #Grid class="ag-theme-balham table-width-fields tablestyles square scroolbar-dusty-grass square thin"
                           style="height: 73.1vh;"
                           [columnDefs]="testScriptColumn"  [animateRows]="true"
                           [frameworkComponents]="frameworkComponents" (rowSelected)="onRowSelected($event)"
                           [defaultColDef]="defaultColDefTestScript" [suppressPaginationPanel]="true" [rowSelection]="rowSelection"
                           [rowHeight]="35" [headerHeight]="35" [enableBrowserTooltips]="true" [floatingFiltersHeight]="45"
                           [enableRangeSelection]="true" [debug]="true" (gridReady)="onGridReadyForTools($event)"
                           [hidden]="!true">
          </ag-grid-angular>
          <div style="    border: 1px solid #c6c6c6;border-top: 0px;text-align: center;">
            <button class="createCycle btn  z-depth-1 waves-effect waves-light text-capitalize" [style.border-color]="backgroundColorButton" title="Create Cycle" (click) = "createCycle()">Create Cycle

            </button>
            <button class="popup gridColour btn  z-depth-1 waves-effect waves-light text-capitalize" [style.background-color]="backgroundColorButton" title="Run Scripts" (click) ="runScript($event)">Run Scripts

            </button>
          </div>
        </div>

    </div>
      </div>
      <div style="margin-top: 4px;
        width: 1196px;">
        <div>

          <mat-toolbar class="agGridtoperDiv card" style="
            background: rgb(228 227 230 / 41%);border-top: 1px solid #a9a9a9a8;
    border-right: 1px solid #a9a9a9a8;
    border-left: 1px solid #a9a9a9a8;">
            <label i18n="@@spmsUserReg" class="textColour">Test Execution Results</label>
           
            <span class="example-spacer"></span>
            <button class="refreshrunButton popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize" [style.background-color]="backgroundColorButton" title="Refresh" (click) = "refreshRun()">
              Refresh<em style="padding: 0px 0px 0px 10px;" class="fas fa-refresh" title="Refresh"></em>
          </button>
            <button class="popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize" [style.background-color]="backgroundColorButton" title="Download" (click) = "onBtExport()">Download
              <em style="padding: 0px 0px 0px 10px;" class="fas fa-download example-icon" title="Download"></em>
            </button>
            <div class="insertPageNoDiv">
              <mat-form-field appearance="outline">
                <mat-label i18n="@@Targetpageno">Page No</mat-label>
                <input matInput type="text" (input)="onSearchChange($event.target.value)">
              </mat-form-field>
            </div>
            <div class="selectRecordsDiv" style="width:9.5%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label i18n="@@Targetrecords" style="color:black">Records</mat-label>
                <mat-select [(ngModel)]="pageSize" (ngModelChange)="onPageSizeChanged(value)">
                  <mat-option value="10" selected>10</mat-option>
                  <mat-option value="200">200</mat-option>
                  <mat-option value="300">300</mat-option>
                  <mat-option value="400">400</mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="card pagination paginationFirstButton paginationFirstlastButton">
              <em class="fas fa-angle-double-left waves-effect waves-light" (click)="onBtFirst()"></em>
            </div>
            <div class="card pagination paginationButtons btn-rounded">
              <ul>
                <li class="paginationliicon"><em class="fas fa-angle-left waves-effect waves-light"
                                                (click)="onBtPrevious()"></em></li>
                <li>{{currentPage}}/{{totalPages}}</li>
                <li class="paginationliicon" style="padding:0;"><em
                  class="fas fa-angle-right waves-effect waves-light" (click)="onBtNext()"></em></li>
              </ul>
            </div>
            <div class="card pagination paginationLastButton paginationFirstlastButton">
              <em class="fas fa-angle-double-right waves-effect waves-light" (click)="onBtLast()"></em>
            </div>

          </mat-toolbar>

         
        </div>
        <ag-grid-angular class="ag-theme-balham table-width tablestyles square scroolbar-dusty-grass square thin"
                         [columnDefs]="columnDefs" [animateRows]="true"  [pagination]="true"
                         [rowSelection]="rowSelection" [defaultColDef]="defaultColDef" [suppressPaginationPanel]="true" [rowHeight]="35"
                         [headerHeight]="35" [enableBrowserTooltips]="true" [floatingFiltersHeight]="45"
                         [paginationNumberFormatter]="paginationNumberFormatter" [paginationPageSize]="paginationPageSize"
                         [enableRangeSelection]="true" [debug]="true" [rowClassRules]="rowClassRules"
                         [frameworkComponents]="frameworkComponents" (paginationChanged)="onPaginationChanged()"
                         (gridReady)="onGridReady($event)" [hidden]="!true"></ag-grid-angular>

      </div>
    </div>
  </div>
</div>
