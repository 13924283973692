import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-default',
  template:
    `<mat-radio-button  (click)="selectOption($event)" [checked]="defaultInstances.defaultinstance" style="padding: .3rem .8rem;margin-top:0rem;color:black">
  </mat-radio-button>`

})

export class Radiobutton implements ICellRendererAngularComp {

  params;
  label: string;
  isDefault: boolean = false;
  defaultInstances: any = [];
  defaultinstance;


  agInit(params): void {
    this.params = params;
    console.log(this.params);
    this.defaultInstances = this.params.data;

    // this.defaultInstances.defaultinstance === 'Yes' ? this.defaultInstances.defaultinstance = true : this.defaultInstances.defaultinstance = false;

  }

  refresh(params?: any): boolean {
    return true;
  }

  selectOption($event) {
    console.log($event)
    console.log(this.params.selectOption)
    // console.log(event.value);
    if (this.params.selectOption instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,

        // ...something
      }
      this.params.selectOption(params);

    }

  }

}

