import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-generatedSplitReport',
  template: `
  <a (click)="downloadgeneratedSplitReport($event)" [disabled]="isdisable" style="color: cornflowerblue;text-decoration: underline;">{{summaryDate}}</a>`
})
export class GeneratedSplitReport implements ICellRendererAngularComp {

  params;
  label: string;
  summaryDate
  isdisable:boolean=false;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params);
    if(params.colDef.headerName== 'Download Split Report'){
      this.isdisable=true;
      if(params.data.migrationType=="Data Migration"){
        this.isdisable=false;
      }

    }
    this.summaryDate=this.params.data.splitReportLastRun;
    console.log(this.summaryDate);
  }

  refresh(params?: any): boolean {
    return true;
  }

  downloadgeneratedSplitReport($event) {
    console.log("hello")
    if (this.params.downloadgeneratedSplitReport instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.downloadgeneratedSplitReport(params);

    }
  }
}
