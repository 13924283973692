import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-actionCheckBox',
  template: `
  <mat-checkbox class="example-margin" [(ngModel)]="defaultcheckbox.selectinstance" (change)="updateCheckbox($event)"></mat-checkbox>
 `
})


export class ActionCheckBox implements ICellRendererAngularComp {

  params:any;
  label: string;
  defaultcheckbox: any = [];

  agInit(params): void {
    this.params = params;
    console.log(this.params);
    this.defaultcheckbox = this.params.data;
      this.defaultcheckbox.selectinstance === "1" ? this.defaultcheckbox.selectinstance = true : this.defaultcheckbox.selectinstance = false;
    // this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  updateCheckbox($event) {
    console.log($event)
    console.log(this.params.updateCheckbox)
    if (this.params.updateCheckbox instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.updateCheckbox(params);

    }
  }

}
