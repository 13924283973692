import { Injectable, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class validationmethod implements OnInit {
    FormValid: UntypedFormGroup;
    constructor(private snackbar: MatSnackBar) {}
    ngOnInit() {

    }
    //Validating all form fields
    validateAllFormFields(formGroup: UntypedFormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof UntypedFormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }

    //validation checking type 1
    getFormValidationErrors() {
        Object.keys(this.FormValid.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.FormValid.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {

                });
            }
        });
    }

    getvalidationmessage() {
        this.snackbar.open("All mandatory fields to be filled ", "OK", {
            duration: 5000,
        });
    }

    materialQuantitygetValidationMsg() {
        this.snackbar.open("Dispatch quantity should not be greater than available quantity ", "OK", {
            duration: 5000,
        });
    }

    materialQtygetValidationMsg() {
        this.snackbar.open("Dispatch quantity should not be greater than MR quantity ", "OK", {
            duration: 5000,
        });
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    equalto(field_name): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            let input = control.value;

            let isValid = control.root.value[field_name] == input
            if (!isValid)
                return { 'equalto': { isValid } }
            else
                return null;
        };
    }
}
