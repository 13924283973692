import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
    selector: 'app-action',
    template: `
      <button title="Edit" (click)="edit($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
        <i class="fas fa-pencil-alt"></i>
      </button>`
  })

export class EditButton implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  edit($event) {
    if (this.params.edit instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.edit(params);

    }
  }
//   delete($event) {
//     if (this.params.delete instanceof Function) {
//       // put anything into params u want pass into parents component
//       const params = {
//         event: $event,
//         rowData: this.params.node.data
//         // ...something
//       }
//       this.params.delete(params);

//     }
//   }
}