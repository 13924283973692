import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {RestApi} from '../RestApi/restApi';
import {Router} from '@angular/router';

interface TestExecutionServiceInfo {
  statusMessage: any;
  error: boolean;
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class TestExecutionService {

  private headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxUiLoaderService,
    private restAPI: RestApi,
    private router: Router
  ) { }

  retrieveTestExecutions(testCycleMstrId) {

    return this.http.get<TestExecutionServiceInfo>(this.restAPI.RETRIEVETESTEXECUTIONS+"/"+testCycleMstrId, { headers: this.headers });

  }
  insertUpdateTestExecution(testExecutionData) {

    return this.http.post<TestExecutionServiceInfo>(this.restAPI.INSERTUPDATETESTEXECUTION,testExecutionData ,{ headers: this.headers });

  }
  deleteTestExecution(id) {
    return this.http.delete<TestExecutionServiceInfo>(this.restAPI.DELETETESTEXECUTION+"/"+id, { headers: this.headers });
  }

  retrieveTestExecutionByCycleMstrId(cycleMstrId) {

    return this.http.get<TestExecutionServiceInfo>(this.restAPI.RETRIEVETESTEXECUTIONBYCYCLEMSTRID+"/"+cycleMstrId, { headers: this.headers });

  }

  retrieveScriptsVersion(scriptId){
    return this.http.get<TestExecutionServiceInfo>(this.restAPI.RETRIEVE_SCRIPTS_VERSION+"/"+ scriptId,{ headers: this.headers })

  }
}
