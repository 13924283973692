import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { TestExecutionService } from '../../services/test-execution-service';
import { TestExecutionControllerComponent } from 'src/app/user/test-execution-controller/test-execution-controller.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-action',
  template: `
  <mat-select [(ngModel)]="selectedValue" (ngModelChange)="onValueChanged()">
  <mat-option value="Select Version">Select Version</mat-option>
    <mat-option *ngFor="let option of dropdownOptions" [value]="option">{{ option}}</mat-option>

  </mat-select>
`,

 
})
export class DropdownComponent implements ICellRendererAngularComp {
  private params: any;
  public static scriptId : any;
  public selectedValue: any = 'Select Version'; // Default to 'Select Version'
  dropdownOptions;
 


  constructor( private testExecutionService:TestExecutionService,private x : TestExecutionControllerComponent,
    private spinner: NgxUiLoaderService) { }

  agInit(params: any): void {
    this.params = params;
    console.log(params.data)
    DropdownComponent.scriptId = params.data.manageTestScriptsMstrId;
   this.testExecutionService.retrieveScriptsVersion( params.data.manageTestScriptsMstrId).subscribe(response=>{
    console.log(DropdownComponent.scriptId)
    console.log(response)
    this.dropdownOptions = response;
   })
  }


  refresh(params: any): boolean {
    console.log(params)
    return true;
  }

  onValueChange(event) {
    console.log(event)
    this.params.setValue(this.selectedValue);
     console.log(this.x.testExecutionData);
     for(let i = 0;i<this.x.testExecutionData.length;i++){
    //   if(this.x.testExecutionData[i])
      }

  }

  onValueChanged() {
    // Call the callback function with the selected value
    this.params.onValueChange(this.selectedValue);
  }
  


}
