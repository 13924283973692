import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';

import $ from 'jquery';

declare const myCronJobInterVal: any;

@Component({
  selector: 'app-cron-job-interval',
  templateUrl: './cron-job-interval.component.html',
  styleUrls: ['./cron-job-interval.component.scss']
})
export class CronJobIntervalComponent implements OnInit {

  cronJob: any;

  backgroundColorButton = "";

  data: any;

  constructor(
    private dialogref: MatDialogRef<CronJobIntervalComponent>,
    private localStorageSettings: LocalStorageSettingsService,
  ) { }

  ngOnInit() {

    this.cronJob = myCronJobInterVal('cronJob1', {
      position: 'start',
      draggable: true
    });

    this.backgroundColorButton = this.localStorageSettings.getSettings("ColorA");

    console.log(this.backgroundColorButton);

  }

  close() {

    var data = $('#manual_expression').val();
    console.log(`showing from jquery : ${data}`);

    this.dialogref.close({
      data
    });

  }

  modelClose() {
    this.dialogref.close();
  }

}
