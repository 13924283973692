    <div class="popup marginBottomStyle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="position: relative;overflow: hidden;height: 100%;">
    <div cdkDragHandle>


            <label i18n="@@spmsUserReg" class="textColour">{{headerNameHtml}}</label>
            <span class="example-spacer"></span>

    </div>
    <div *ngIf="parentScreen" style="height:83%;overflow:hidden;">
    <div style="height:98%;overflow:auto">

        <form novalidate [formGroup]="formValid" style="margin-bottom: 3em;">

            <div class="container">

                <div style="max-width: 0; max-height: 0; overflow: hidden;">

                    <input autofocus="true" />

                </div>

                <div class="row topRowMargins" *ngFor="let ngModelObjectFourTemporary of ngModelObjectDivision">

                    <div class="col-lg-1" *ngIf="bootstrapColumn==1">

                    </div>

                    <div class="col-lg-2" *ngIf="bootstrapColumn==2">

                    </div>

                    <div class={{ngModelTemporary.bootstrapColumnGrid}} *ngFor = "let ngModelTemporary of ngModelObjectFourTemporary">

                        <div *ngIf="ngModelTemporary.fieldType=='valueDropDown' || ngModelTemporary.fieldType=='valueParentDropDown' ||  ngModelTemporary.fieldType == 'Select List - Static' || ngModelTemporary.fieldType == 'Linked Fields'">

                            <mat-form-field appearance="outline" *ngIf="checkToShowSelectDropDown(ngModelTemporary,systemName,headerNameHtml)" >

                                <mat-label i18n="@@Targetstdcode">{{ngModelTemporary.formName}}</mat-label>

                                <mat-select formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" (ngModelChange)="childDropDown(ngModelTemporary)" [disabled]="disableFields(headerNameHtml,ngModelTemporary.formName)">

                                    <mat-option>None</mat-option>
                                   
                                        <span *ngFor="let temporaryObject of listObjectDropDown[ngModelTemporary.dropDownIndex]">
                                            <span *ngIf="headerNameHtml == 'Data Source Configuration' && ngModelTemporary.formName == 'Reports Target Vault';else TenantMangmentDropDown">
                                                <span *ngFor="let tempObject of temporaryObject.keyJson">
                                                    <mat-option *ngIf="temporaryObject.systemname1 == 'VAULT'" [value]="tempObject" [disabled]="temporaryObject.status == 'No'">
                                                        {{temporaryObject.dropDownValueDisplay}}
                                                    </mat-option>
                                                </span>
                                           
                                        </span>
                                        <ng-template #TenantMangmentDropDown>
                                            <span *ngIf="headerNameHtml == 'Tenant Management' && (ngModelTemporary.formName == 'Source' || ngModelTemporary.formName == 'Target');else normalOptions">
                                                <span *ngFor="let tempObject of temporaryObject.keyJson">
                                                    <mat-option *ngIf="temporaryObject.companyid == companyId" [value]="tempObject" [disabled]="temporaryObject.status == 'No'">
                                                        {{temporaryObject.dropDownValueDisplay}}
                                                    </mat-option>
                                                </span>
                                           
                                        </span>
                                        <ng-template #normalOptions>
                                            <mat-option *ngFor="let tempObject of temporaryObject.keyJson" [value]="tempObject" [disabled]="temporaryObject.status == 'No'">
                                                {{temporaryObject.dropDownValueDisplay}}
                                            </mat-option>
                                            </ng-template>
                                        </ng-template>
                        
                                    </span>
                                    
                                    

                                </mat-select>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueText' || ngModelTemporary.fieldType=='valueEmail' || ngModelTemporary.fieldType=='Email' || ngModelTemporary.fieldType=='Free Text Single line' || ngModelTemporary.fieldType=='Attachment File'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" [readonly]="ngModelTemporary.readOnlyValue || disableFields(headerNameHtml,ngModelTemporary.formName)" >

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='Color'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput type="color" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" [readonly]="ngModelTemporary.readOnlyValue" matTooltip={{ngModelTemporary.keyValue}} matTooltipPosition="right" [matTooltipClass]="{'tooltipCss':true}">

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='DatePicker' || ngModelTemporary.fieldType=='Start & End Date'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetAccountNumber">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [matDatepicker]="i"
                                [min]="ngModelTemporary.endDateValueMin" [max]="ngModelTemporary.endDateValue" readonly [required]="ngModelTemporary.mandatory">

                                <mat-datepicker-toggle matSuffix [for]="i">

                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                </mat-datepicker-toggle>

                                <mat-datepicker #i></mat-datepicker>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('matDatepickerParse') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueDate' || ngModelTemporary.fieldType=='valueStartDate' || ngModelTemporary.fieldType=='valueEndDate'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetAccountNumber">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [matDatepicker]="i"
                                [min]="ngModelTemporary.startDateValue" [max]="ngModelTemporary.endDateValue" readonly [required]="ngModelTemporary.mandatory" (dateChange)="changeStartEndDateValue(ngModelTemporary)">

                                <mat-datepicker-toggle matSuffix [for]="i">

                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                </mat-datepicker-toggle>

                                <mat-datepicker #i></mat-datepicker>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('matDatepickerParse') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='DateTimePicker' || ngModelTemporary.fieldType=='Start & End Date Time'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetDateTime">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput [(ngModel)]="ngModelTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelTemporary.fieldName}} [min]="ngModelTemporary.endDateTimeValueMin" [max]="ngModelTemporary.endDateTimeValue" readonly [required]="ngModelTemporary.mandatory">

                                <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                <owl-date-time #i></owl-date-time>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueDateTime' || ngModelTemporary.fieldType=='valueStartDateTime' || ngModelTemporary.fieldType=='valueEndDateTime'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetDateTime">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput [(ngModel)]="ngModelTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelTemporary.fieldName}} [min]="ngModelTemporary.startDateTimeValue" [max]="ngModelTemporary.endDateTimeValue" readonly [required]="ngModelTemporary.mandatory" (dateTimeChange)="changeStartEndDateTimeValue(ngModelTemporary)">

                                <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                <owl-date-time #i></owl-date-time>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueFilePath' || ngModelTemporary.fieldType=='Attachment'">
                            <div class = "row" style="margin-left:0px;margin-right:0px">

                                <div class="col-lg-3" style="padding-left:inherit">

                                    <div class="file-field">

                                        <a class="btn-floating  btn-sm m-0">

                                        <em class="fas fa-paperclip mt-0" style="text-rendering: auto;color:black"></em>

                                        <input type="file" (change)="imageFunction($event,ngModelTemporary)" formControlName={{ngModelTemporary.fieldName}}>

                                        </a>

                                    </div>

                                </div>

                                <div class="col-lg-7" style="padding-left:0px;padding-right:0px">

                                    <div *ngIf="ngModelTemporary.imageShow" style="width:100%;height:75px;display: inline-block;">

                                        <div *ngIf="ngModelTemporary.imageShow1">

                                            <div class="card" style="width:75%; display: inline-block;margin:0% 1%;height: 100%;">

                                                <div class="cardButtonDiv">

                                                    <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteImage(ngModelTemporary)">

                                                    <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                    </button>

                                                </div>

                                                <!-- Card content -->
                                                <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;" (click)="openImage(ngModelTemporary.keyValue)">

                                                    <!-- Card image -->
                                                    <div>

                                                        <img id="expand" class="card-img-top" [src]="getSanitizer(ngModelTemporary.keyValue)" alt="Card Image cap" style="width: 100%;height: 75px;">

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div *ngIf="ngModelTemporary.fileShow">

                                            <div class="card" style="width:100%; display: inline-block;margin:0% 1%;background-color:lightcyan">

                                                <div class="cardButtonDiv">

                                                    <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteImage(ngModelTemporary)">

                                                    <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                    </button>

                                                </div>

                                                <!-- Card content -->
                                                <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;">

                                                    <span>{{ngModelTemporary.imageNameDisplay}}</span>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-lg-2">

                                    <div *ngIf="ngModelTemporary.downloadIconShow">

                                        <a (click)="docdownload(ngModelTemporary)" style="font-size:1.5rem;color:green"><em class="fas fa-download" title="Download"></em></a>

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valuePassword' || ngModelTemporary.fieldType=='Password'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput [type]="hide ? 'text' : 'password'" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">

                                <mat-icon matSuffix *ngIf="eyeIcon" (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}
                                </mat-icon>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueInteger' || ngModelTemporary.fieldType=='Integer Number' ">
                           <div *ngIf="ngModelTemporary.fieldName!== 'sessionexpirationtime' &&ngModelTemporary.fieldName!== 'burstlimitthreshold' && ngModelTemporary.fieldName!== 'burstlimitsleeptime'">
                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">

                                <mat-error  *ngFor="let validation of ngModelTemporary.validationMessages">


                                        <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                            {{ validation.message }}

                                        </span>




                                </mat-error>

                            </mat-form-field>
                           </div>


                        </div>
                        <div *ngIf="(ngModelTemporary.fieldName=='sessionexpirationtime'|| ngModelTemporary.fieldName=='burstlimitthreshold' || ngModelTemporary.fieldName=='burstlimitsleeptime') && systemName == 'Vault'" >
                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>
                           </div>


                        <div *ngIf="ngModelTemporary.fieldType=='valueDecimal' || ngModelTemporary.fieldType=='Decimal Number'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetNumberNumeric">{{ngModelTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" [readonly]="ngModelTemporary.readOnlyValue">

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueCheckbox' || ngModelTemporary.fieldType=='Checkbox'">

                            <mat-checkbox formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">{{ngModelTemporary.formName}}</mat-checkbox>

                            <mat-error style="font-size:75%;font-weight:normal" *ngFor="let validation of ngModelTemporary.validationMessages">

                                <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                    {{ validation.message }}

                                </span>

                            </mat-error>

                        </div>
                        <div *ngIf="ngModelTemporary.fieldType=='valueRadio' || ngModelTemporary.fieldType=='Radio'" appearance="outline">
                        <div  style="display: flex;margin-top: 7px;">
                            <label style="padding: 0px 5px;font-size: 14px;">{{ngModelTemporary.formName}} : </label>
                            <mat-radio-group style="display: grid;margin-top: -1px;"  formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">
                            <mat-radio-button style="padding: 0px 5px;" *ngFor = "let eachradiovalue of ngModelTemporary.radio" [value]="eachradiovalue" (change)="radiochange($event)">{{eachradiovalue}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-error style="    font-size: 75%;
                    font-weight: normal;" *ngFor="let validation of ngModelTemporary.validationMessages">

                        <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                            {{ validation.message }}

                        </span>

                    </mat-error>

                        </div>

                        <div *ngIf="ngModelTemporary.fieldType=='valueTextArea' || ngModelTemporary.fieldType=='Free Text Multi line' || ngModelTemporary.fieldType=='JSON'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                <textarea matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">{{ngModelTemporary.keyValue}}</textarea>

                                <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                    <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                    </div>

                    <div class="col-lg-1" *ngIf="bootstrapColumn==1" style="padding-left:0px">

                        <div *ngIf="tableName=='createschedulesmstr' && ngModelObjectFourTemporary.length>0">

                            <div *ngIf="ngModelObjectFourTemporary[0].keyName=='scheduleinterval'">

                                <button title="Edit" (click)="scheduleIntervalButton();" class="btn btn-primary btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem">
                                    <em class="fas fa-share"></em>
                                </button>

                            </div>

                        </div>

                    </div>

                    <div class="col-lg-2" *ngIf="bootstrapColumn==2">

                    </div>

                </div>

            </div>

        </form>

    </div>

</div>

<div *ngIf="parentScreen" class="row rowMarginStyle" style="left:0;bottom:0">

        <div class="col-lg text-center" style="padding-right:10px">



            <div style="text-align: end">

                <form novalidate [formGroup]="formValidCheckbox">

                    <span *ngIf='buttonNameClose=="Save" && nextSaveTOrM!="Next"'>

                        <mat-checkbox formControlName="isCheckboxSelected" [(ngModel)]="isCheckboxSelected" style="margin-right:1%" class="mat-checkbox-layout"></mat-checkbox>

                        <span>

                            Create another

                        </span>

                    </span>

                    <span *ngIf="updateButton">

                        <button i18n="@@TargetClear" type="button" (click)="sendDataAndClose()"
                        class="actionButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                        style="padding-left:1.2rem;padding-right: 1.2rem" [style.background-color]="backgroundColorButton"  [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean">{{buttonNameClose}}</button>

                        <button i18n="@@TargetCancel" type="button" (click)="cancel()"
                        class="cancelButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                        style="padding-left:1.2rem;padding-right: 1.2rem" [style.border-color]="backgroundColorButton" [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean" >Cancel</button>

                    </span>

                    <span *ngIf="nextButton">

                        <button i18n="@@TargetClear" type="button" (click)="next(trnxScreenId)"
                        class="actionButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                        style="padding-left:1.2rem;padding-right: 1.2rem" [style.background-color]="backgroundColorButton" [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean">{{nextSaveTOrM}}</button>

                        <button i18n="@@TargetCancel" type="button" (click)="cancel()"
                        class="cancelButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                        style="padding-left:1.2rem;padding-right: 1.2rem" [style.border-color]="backgroundColorButton" [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean" >Cancel</button>

                    </span>

                </form>

            </div>

        </div>

    </div>


<div *ngIf="childScreen" style="height:93%;overflow:hidden;">


  <div *ngIf="headerNameHtml=='Application Page Library'">
    <label style="margin-top: 20px; font-weight:400; font-size: 3.5mm;">Page and Script Association</label>
  </div>
  <div *ngIf="headerNameHtml!=='Application Page Library'">
    <label style="margin-top: 10px;">Master Data</label>
  </div>
    <div style="height: 20%;
    display: block;
    overflow-x: auto;
    overflow-y: hidden;">
         <form novalidate [formGroup]="formValid" style="margin-top: 2px;">

             <div class="container">

                 <div style="max-width: 0; max-height: 0; overflow: hidden;">

                     <input autofocus="true" />

                 </div>

                 <div style="display: flex;">
                 <div style="margin-right: 5px; width: 300;" *ngFor="let ngModelObjectFourTemporary of ngModelObjectDivision">

                     <div  *ngFor = "let ngModelTemporary of ngModelObjectFourTemporary">

                         <div *ngIf="ngModelTemporary.fieldType=='valueDropDown' || ngModelTemporary.fieldType=='valueParentDropDown' ||  ngModelTemporary.fieldType == 'Select List - Static' || ngModelTemporary.fieldType == 'Linked Fields'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="@@Targetstdcode">{{ngModelTemporary.formName}}</mat-label>

                                 <mat-select formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" (ngModelChange)="childDropDown(ngModelTemporary)">

                                     <mat-option>None</mat-option>

                                     <span *ngFor="let temporaryObject of listObjectDropDown[ngModelTemporary.dropDownIndex]">

                                         <mat-option *ngFor="let tempObject of temporaryObject.keyJson" [value]="tempObject" [disabled]="temporaryObject.status == 'No'">

                                             {{temporaryObject.dropDownValueDisplay}}

                                         </mat-option>

                                     </span>

                                 </mat-select>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueText' || ngModelTemporary.fieldType=='valueEmail' || ngModelTemporary.fieldType=='Email' || ngModelTemporary.fieldType=='Free Text Single line' || ngModelTemporary.fieldType=='Attachment File'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" [readonly]="ngModelTemporary.readOnlyValue">

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='DatePicker' || ngModelTemporary.fieldType=='Start & End Date'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="TargetAccountNumber">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [matDatepicker]="i"
                                 [min]="ngModelTemporary.endDateValueMin" [max]="ngModelTemporary.endDateValue" readonly [required]="ngModelTemporary.mandatory">

                                 <mat-datepicker-toggle matSuffix [for]="i">

                                     <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                 </mat-datepicker-toggle>

                                 <mat-datepicker #i></mat-datepicker>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('matDatepickerParse') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueDate' || ngModelTemporary.fieldType=='valueStartDate' || ngModelTemporary.fieldType=='valueEndDate'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="TargetAccountNumber">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [matDatepicker]="i"
                                 [min]="ngModelTemporary.startDateValue" [max]="ngModelTemporary.endDateValue" readonly [required]="ngModelTemporary.mandatory" (dateChange)="changeStartEndDateValue(ngModelTemporary)">

                                 <mat-datepicker-toggle matSuffix [for]="i">

                                     <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                 </mat-datepicker-toggle>

                                 <mat-datepicker #i></mat-datepicker>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('matDatepickerParse') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='DateTimePicker' || ngModelTemporary.fieldType=='Start & End Date Time'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="TargetDateTime">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput [(ngModel)]="ngModelTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelTemporary.fieldName}} [min]="ngModelTemporary.endDateTimeValueMin" [max]="ngModelTemporary.endDateTimeValue" readonly [required]="ngModelTemporary.mandatory">

                                 <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                 <owl-date-time #i></owl-date-time>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueDateTime' || ngModelTemporary.fieldType=='valueStartDateTime' || ngModelTemporary.fieldType=='valueEndDateTime'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="TargetDateTime">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput [(ngModel)]="ngModelTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelTemporary.fieldName}} [min]="ngModelTemporary.startDateTimeValue" [max]="ngModelTemporary.endDateTimeValue" readonly [required]="ngModelTemporary.mandatory" (dateTimeChange)="changeStartEndDateTimeValue(ngModelTemporary)">

                                 <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                 <owl-date-time #i></owl-date-time>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError('required') && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueFilePath' || ngModelTemporary.fieldType=='Attachment'">
                             <div class = "row" style="margin-left:0px;margin-right:0px">

                                 <div class="col-lg-3" style="padding-left:inherit">

                                     <div class="file-field">

                                         <a class="btn-floating  btn-sm m-0">

                                         <em class="fas fa-paperclip mt-0" style="text-rendering: auto;color:black"></em>

                                         <input type="file" (change)="imageFunction($event,ngModelTemporary)" formControlName={{ngModelTemporary.fieldName}}>

                                         </a>

                                     </div>

                                 </div>

                                 <div class="col-lg-7" style="padding-left:0px;padding-right:0px">

                                     <div *ngIf="ngModelTemporary.imageShow" style="width:100%;height:75px;display: inline-block;">

                                         <div *ngIf="ngModelTemporary.imageShow1">

                                             <div class="card" style="width:75%; display: inline-block;margin:0% 1%;height: 100%;">

                                                 <div class="cardButtonDiv">

                                                     <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteImage(ngModelTemporary)">

                                                     <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                     </button>

                                                 </div>

                                                 <!-- Card content -->
                                                 <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;" (click)="openImage(ngModelTemporary.keyValue)">

                                                     <!-- Card image -->
                                                     <div>

                                                         <img id="expand" class="card-img-top" [src]="getSanitizer(ngModelTemporary.keyValue)" alt="Card Image cap" style="width: 100%;height: 75px;">

                                                     </div>

                                                 </div>

                                             </div>

                                         </div>

                                         <div *ngIf="ngModelTemporary.fileShow">

                                             <div class="card" style="width:100%; display: inline-block;margin:0% 1%;background-color:lightcyan">

                                                 <div class="cardButtonDiv">

                                                     <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteImage(ngModelTemporary)">

                                                     <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                     </button>

                                                 </div>

                                                 <!-- Card content -->
                                                 <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;">

                                                     <span>{{ngModelTemporary.imageNameDisplay}}</span>

                                                 </div>

                                             </div>

                                         </div>

                                     </div>

                                 </div>

                                 <div class="col-lg-2">

                                     <div *ngIf="ngModelTemporary.downloadIconShow">

                                         <a (click)="docdownload(ngModelTemporary)" style="font-size:1.5rem;color:green"><em class="fas fa-download" title="Download"></em></a>

                                     </div>

                                 </div>

                             </div>
                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valuePassword' || ngModelTemporary.fieldType=='Password'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput [type]="hide ? 'text' : 'password'" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">

                                 <mat-icon matSuffix *ngIf="eyeIcon" (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}
                                 </mat-icon>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueInteger' || ngModelTemporary.fieldType=='Integer Number'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueDecimal' || ngModelTemporary.fieldType=='Decimal Number'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="TargetNumberNumeric">{{ngModelTemporary.formName}}</mat-label>

                                 <input matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory" [readonly]="ngModelTemporary.readOnlyValue">

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueCheckbox' || ngModelTemporary.fieldType=='Checkbox'">

                             <mat-checkbox formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">{{ngModelTemporary.formName}}</mat-checkbox>

                             <mat-error style="font-size:75%;font-weight:normal" *ngFor="let validation of ngModelTemporary.validationMessages">

                                 <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                     {{ validation.message }}

                                 </span>

                             </mat-error>

                         </div>
                         <div *ngIf="ngModelTemporary.fieldType=='valueRadio' || ngModelTemporary.fieldType=='Radio'" appearance="outline">
                         <div  style="display: flex;margin-top: 7px;">
                             <label style="padding: 0px 5px;font-size: 14px;">{{ngModelTemporary.formName}} : </label>
                             <mat-radio-group style="display: grid;margin-top: -1px;"  formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">
                             <mat-radio-button style="padding: 0px 5px;" *ngFor = "let eachradiovalue of ngModelTemporary.radio" [value]="eachradiovalue" (change)="radiochange($event)">{{eachradiovalue}}</mat-radio-button>
                         </mat-radio-group>
                     </div>
                     <mat-error style="    font-size: 75%;
                     font-weight: normal;" *ngFor="let validation of ngModelTemporary.validationMessages">

                         <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                             {{ validation.message }}

                         </span>

                     </mat-error>

                         </div>

                         <div *ngIf="ngModelTemporary.fieldType=='valueTextArea' || ngModelTemporary.fieldType=='Free Text Multi line' || ngModelTemporary.fieldType=='JSON'">

                             <mat-form-field appearance="outline">

                                 <mat-label i18n="Targetjointno">{{ngModelTemporary.formName}}</mat-label>

                                 <textarea matInput type="text" formControlName={{ngModelTemporary.fieldName}} [(ngModel)]="ngModelTemporary.keyValue" [required]="ngModelTemporary.mandatory">{{ngModelTemporary.keyValue}}</textarea>

                                 <mat-error *ngFor="let validation of ngModelTemporary.validationMessages">

                                     <span *ngIf="formValid.get(ngModelTemporary.fieldName).hasError(validation.type) && (formValid.get(ngModelTemporary.fieldName).dirty || formValid.get(ngModelTemporary.fieldName).touched)">

                                         {{ validation.message }}

                                     </span>

                                 </mat-error>

                             </mat-form-field>

                         </div>

                     </div>

                 </div>

                </div>

             </div>

         </form>

     </div>

     <!--inster details data-->
  <div *ngIf="headerNameHtml=='Application Page Library'">
    <label style="margin-top: 20px; font-weight: 400; font-size: 3.5mm;">Associated Test Scripts</label>
  </div>
  <div *ngIf="headerNameHtml!=='Application Page Library'">
    <label style="margin-top: 20px;">Details Data</label>
  </div>
    <div style="
    height: 50%;
    overflow-x: auto;">

        <div style="display: flex;">
        <form novalidate [formGroup]="formChildValid" style="border-bottom: 1px dotted; width: 250px">

            <div class="container">

                <div style="max-width: 0; max-height: 0; overflow: hidden;">

                    <input autofocus="true" />

                </div>

                <div style="display: flex;">
                <div style="margin-right: 5px; width: 950px" *ngFor="let ngModelChildObjectFourTemporary of ngModelChildObjectDivision">

                    <div *ngFor = "let ngModelChildTemporary of ngModelChildObjectFourTemporary">

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueDropDown' || ngModelChildTemporary.fieldType=='valueParentDropDown' || ngModelChildTemporary.fieldType=='Linked Fields' || ngModelChildTemporary.fieldType == 'Select List - Static'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="@@Targetstdcode">{{ngModelChildTemporary.formName}}</mat-label>

                                <mat-select formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory" (ngModelChange)="childDropDown(ngModelChildTemporary)">

                                    <mat-option>None</mat-option>

                                    <span *ngFor="let temporaryChildObject of listChildObjectDropDown[ngModelChildTemporary.dropDownIndex]">

                                        <mat-option *ngFor="let tempChildObject of temporaryChildObject.keyJson" [value]="tempChildObject" [disabled]="temporaryChildObject.status == 'No'">

                                            {{temporaryChildObject.dropDownValueDisplay}}

                                        </mat-option>

                                    </span>

                                </mat-select>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueText' || ngModelChildTemporary.fieldType=='valueEmail' ||ngModelChildTemporary.fieldType=='Email' || ngModelChildTemporary.fieldType=='Free Text Single line' || ngModelChildTemporary.fieldType=='Attachment File'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory" [readonly]="ngModelChildTemporary.readOnlyValue">

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='DatePicker' || ngModelChildTemporary.fieldType == 'Start & End Date'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetAccountNumber">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [matDatepicker]="i"
                                [min]="ngModelChildTemporary.endDateValueMin" [max]="ngModelChildTemporary.endDateValue" readonly [required]="ngModelChildTemporary.mandatory" (dateChange)="changeStartEndDateValue(ngModelChildTemporary)">

                                <mat-datepicker-toggle matSuffix [for]="i">

                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                </mat-datepicker-toggle>

                                <mat-datepicker #i></mat-datepicker>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('matDatepickerParse') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('required') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueDate' || ngModelChildTemporary.fieldType=='valueStartDate' || ngModelChildTemporary.fieldType=='valueEndDate'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetAccountNumber">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [matDatepicker]="i"
                                [min]="ngModelChildTemporary.startDateValue" [max]="ngModelChildTemporary.endDateValue" readonly [required]="ngModelChildTemporary.mandatory" (dateChange)="changeStartEndDateValue(ngModelChildTemporary)">

                                <mat-datepicker-toggle matSuffix [for]="i">

                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>

                                </mat-datepicker-toggle>

                                <mat-datepicker #i></mat-datepicker>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('matDatepickerParse') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('required') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='DateTimePicker' || ngModelChildTemporary.fieldType == 'Start & End Date Time'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetDateTime">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput [(ngModel)]="ngModelChildTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelChildTemporary.fieldName}} [min]="ngModelChildTemporary.endDateTimeValueMin" [max]="ngModelChildTemporary.endDateTimeValue" readonly [required]="ngModelChildTemporary.mandatory" (dateTimeChange)="changeStartEndDateTimeValue(ngModelChildTemporary)">

                                <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                <owl-date-time #i></owl-date-time>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('required') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueDateTime' || ngModelChildTemporary.fieldType=='valueStartDateTime' || ngModelChildTemporary.fieldType=='valueEndDateTime'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetDateTime">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput [(ngModel)]="ngModelChildTemporary.keyValue" [owlDateTimeTrigger]="i"  [owlDateTime]="i" formControlName={{ngModelChildTemporary.fieldName}} [min]="ngModelChildTemporary.startDateTimeValue" [max]="ngModelChildTemporary.endDateTimeValue" readonly [required]="ngModelChildTemporary.mandatory" (dateTimeChange)="changeStartEndDateTimeValue(ngModelChildTemporary)">

                                <em [owlDateTimeTrigger]="i" class="fas fa-caret-down" style="display: flex; position: absolute; top: 1%; left: 93%;"></em>

                                <owl-date-time #i></owl-date-time>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError('required') && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueFilePath' || ngModelChildTemporary.fieldType=='Attachment'">
                            <div class = "row" style="margin-left:0px;margin-right:0px">

                                <div class="col-lg-3" style="padding-left:inherit">

                                    <div class="file-field">

                                        <a class="btn-floating  btn-sm m-0">

                                        <em class="fas fa-paperclip mt-0" style="text-rendering: auto;color:black"></em>

                                        <input type="file" (change)="imageChildFunction($event,ngModelChildTemporary)" formControlName={{ngModelChildTemporary.fieldName}}>

                                        </a>

                                    </div>

                                </div>

                                <div class="col-lg-7" style="padding-left:0px;padding-right:0px">

                                    <div *ngIf="ngModelChildTemporary.imageShow" style="width:100%;height:75px;display: inline-block;">

                                        <div *ngIf="ngModelChildTemporary.imageShow1">

                                            <div class="card" style="width:75%; display: inline-block;margin:0% 1%;height: 100%;">

                                                <div class="cardButtonDiv">

                                                    <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteChildImage(ngModelChildTemporary)">

                                                    <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                    </button>

                                                </div>

                                                <!-- Card content -->
                                                <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;" (click)="openImage(ngModelChildTemporary.keyValue)">

                                                    <!-- Card image -->
                                                    <div>

                                                        <img id="expand" class="card-img-top" [src]="getSanitizer(ngModelChildTemporary.keyValue)" alt="Card Image cap" style="width: 100%;height: 75px;">

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div *ngIf="ngModelChildTemporary.fileShow">

                                            <div class="card" style="width:100%; display: inline-block;margin:0% 1%;background-color:lightcyan">

                                                <div class="cardButtonDiv">

                                                    <button type="button" class="close badge badge-danger" aria-label="Close" (click)="deleteChildImage(ngModelChildTemporary)">

                                                    <em class="fa fa-times" aria-hidden="true" style="font-family: 'Font Awesome 5 Free';font-weight: 900"></em>

                                                    </button>

                                                </div>

                                                <!-- Card content -->
                                                <div class="card-body" style="padding: 2%;overflow: hidden;height: -webkit-fill-available;">

                                                    <span>{{ngModelChildTemporary.imageNameDisplay}}</span>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-lg-2">

                                    <div *ngIf="ngModelChildTemporary.downloadIconShow">

                                        <a (click)="docdownload(ngModelChildTemporary)" style="font-size:1.5rem;color:green"><em class="fas fa-download" title="Download"></em></a>

                                    </div>

                                </div>

                            </div>
                        </div>




                        <div *ngIf="ngModelChildTemporary.fieldType=='valuePassword' || ngModelChildTemporary.fieldType=='Password'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput [type]="hide ? 'text' : 'password'" formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory">

                                <mat-icon matSuffix *ngIf="eyeIcon" (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}
                                </mat-icon>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueInteger' || ngModelChildTemporary.fieldType=='Integer Number'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory">

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueDecimal' || ngModelChildTemporary.fieldType=='Decimal Number'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="TargetNumberNumeric">{{ngModelChildTemporary.formName}}</mat-label>

                                <input matInput type="text" formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory" [readonly]="ngModelChildTemporary.readOnlyValue">

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueCheckbox' || ngModelChildTemporary.fieldType=='Checkbox'" style="margin-left: 33px;
                        margin-right: 38px;">

                            <mat-checkbox formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory">{{ngModelChildTemporary.formName}}</mat-checkbox>

                            <mat-error style="font-size:75%;font-weight:normal" *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                    {{ validation.message }}

                                </span>

                            </mat-error>

                        </div>
                        <div *ngIf="ngModelChildTemporary.fieldType=='valueRadio' || ngModelChildTemporary.fieldType=='Radio'" appearance="outline">
                        <div  style="display: flex;margin-top: 7px;">
                            <label style="padding: 0px 5px;font-size: 14px;">{{ngModelChildTemporary.formName}} : </label>
                            <mat-radio-group style="display: grid;margin-top: -1px;"  formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory">
                            <mat-radio-button style="padding: 0px 5px;" *ngFor = "let eachradiovalue of ngModelChildTemporary.radio" [value]="eachradiovalue" (change)="radiochange($event)">{{eachradiovalue}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-error style="    font-size: 75%;
                    font-weight: normal;" *ngFor="let validation of ngModelChildTemporary.validationMessages">

                        <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                            {{ validation.message }}

                        </span>

                    </mat-error>

                        </div>

                        <div *ngIf="ngModelChildTemporary.fieldType=='valueTextArea' || ngModelChildTemporary.fieldType=='Free Text Multi line' || ngModelChildTemporary.fieldType=='JSON'">

                            <mat-form-field appearance="outline">

                                <mat-label i18n="Targetjointno">{{ngModelChildTemporary.formName}}</mat-label>

                                <textarea matInput type="text" formControlName={{ngModelChildTemporary.fieldName}} [(ngModel)]="ngModelChildTemporary.keyValue" [required]="ngModelChildTemporary.mandatory">{{ngModelChildTemporary.keyValue}}</textarea>

                                <mat-error *ngFor="let validation of ngModelChildTemporary.validationMessages">

                                    <span *ngIf="formChildValid.get(ngModelChildTemporary.fieldName).hasError(validation.type) && (formChildValid.get(ngModelChildTemporary.fieldName).dirty || formChildValid.get(ngModelChildTemporary.fieldName).touched)">

                                        {{ validation.message }}

                                    </span>

                                </mat-error>

                            </mat-form-field>

                        </div>

                    </div>

                </div>

                </div>

            </div>

        </form>
        <div style="margin-top: 5px;">

            <div class="col-lg text-center">

              <div>
                    <div style="display:inline-block;">

                        <span class="addSpan" style="color:black;font-size:22px">

                            <em class="fas fa-plus-circle" title="Add Field" (click)="addData()"></em>

                        </span>

                    </div>
                  </div>
            </div>

        </div>
    </div>
        <div>
<div *ngFor="let childObject of bindingDetailsData;let indexOfelement=index;">
<div style="display: flex;">
<div style="display: flex;margin-left: 15px;    margin-bottom: -7px;
margin-top: -9px;">
<div style="margin-right:5px;" *ngFor = "let eachchild of childObject">
    <form>
        <mat-form-field style="    padding: 0px !important;
        margin-top: 0px;
        top: 0.7em;" appearance="outline">
    <input matInput type="text" value="{{eachchild.childValue}}" readonly>
    </mat-form-field>
</form>
</div>
</div>
<div style="margin-top: 5px;">

   <div class="col-lg text-center" >


       <div>
           <div style="display:inline-block;">

               <span class="addSpan" style="color:black;font-size:22px">

                   <em class="fas fa-minus-circle" title="Removed Field" (click)="removeData(indexOfelement)"></em>

               </span>

           </div>

   </div>
   </div>

</div>
</div>

</div>
</div>




</div>
    <div class="row mb-1 topRowMargins" style= "position:sticky;left:0;bottom:0;width:100%">

        <div class="col-lg text-center" >

            <div style="text-align: end">

                <form novalidate [formGroup]="formValidCheckbox">

                    <span *ngIf='buttonName=="Save"'>

                        <mat-checkbox formControlName="isCheckboxSelected" [(ngModel)]="isCheckboxSelected" style="margin-right:1%" class="mat-checkbox-layout"></mat-checkbox>

                        <span>

                            Create another

                        </span>

                    </span>

                    <button i18n="@@TargetClear" type="button" (click)="sendDataAndClose()"
                    class="actionButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                    style="padding-left:1.2rem;padding-right: 1.2rem" [style.background-color]="backgroundColorButton" [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean">{{buttonName}}</button>

                    <button i18n="@@TargetCancel" type="button" (click)="closeFunction()"
                    class="cancelButton btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
                    style="padding-left:1.2rem;padding-right: 1.2rem" [style.border-color]="backgroundColorButton" [class.allocateButtons]="allocateButtonsBoolean" [class.allocateButtons1]="allocateButtons1Boolean" >Cancel</button>

                </form>

            </div>

        </div>

    </div>
</div>

</div>
