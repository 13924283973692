import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { rootRouterConfig } from './app.routes';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/shared.module";
import { AgGridModule } from 'ag-grid-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { AppErrorHandler } from './shared/common/app-error-handler';
import { Actionbutton } from 'src/app/shared/common/Actionbutton';
import { UploadButton } from 'src/app/shared/common/uploadButton';
import { Deletebutton } from 'src/app/shared/common/Deletebutton';
import { ResumeLoadButton } from 'src/app/shared/common/resumeLoadButton';
import { GenerateReport } from 'src/app/shared/common/GenerateReport';
import { GeneratedSummaryReport } from 'src/app/shared/common/generatedsummaryreport';
import { FiledownloadComponent } from 'src/app/shared/common/filedownload.component';
import { publishButton } from 'src/app/shared/common/publishButton';
import { httpInterceptorProviders } from './shared/services/auth-interceptor';
import { ImageviewPopupComponent } from 'src/app/user/imageview-popup/imageview-popup.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ImageViewInPopupComponent } from 'src/app/user/image-view-in-popup/image-view-in-popup.component';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ActionCheckBox } from './shared/common/ActionCheckBox';
import { Schedulebutton } from './shared/common/Schedulebutton';
import { ReportTemplateButton } from './shared/common/ReportTemplateButton';
import { UploadToVault } from './shared/common/uploadtovaultbutton';
import { Radiobutton } from './shared/common/Radiobutton';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FileUploadButton } from 'src/app/shared/common/fileUploadbutton';
import { EtlActionCheckBox } from './shared/common/etlActionCheckBox';
import { Migrationtracelogs } from './shared/common/Migrationtracelogs';
import { ValidationReportDownload } from './shared/common/ValidationReportDownload';
import { SummaryReportDownload } from './shared/common/SummaryReportDownload';
import { GeneratedSplitReport } from './shared/common/generatedsplitreport';
import { GenerateButton } from './shared/common/generate-button.component'
import { TreeviewModule } from 'ngx-treeview';
import { LoadToVault } from "./shared/common/LoadToVault";
import { DocAttachmentFilePath } from "./shared/common/doc-attachment-file-path.component";
import { DownloadMappingFile } from "./shared/common/downloadmappingfile";
import { LinkToCasesButton } from "./shared/common/linkToCasesButton";
import { RemoveButton } from "./shared/common/removebutton";
import { CustomCheckBoxButton } from "./shared/common/checkBoxButton";
import { AttachmentButton } from "./shared/common/attachmentButton";
import { DownloadTestResult } from "./shared/common/testResultDownloadButton";
import { InputFileDownloadButton } from "./shared/common/inputFileDownloadButton";
import { VaultObjectRefereshButton } from "./shared/common/vault-object-referesh-button.component";
import { PopupButton } from "./shared/common/popupbutton";
import { DownloadGlobalObjects } from "./shared/common/downloadglobalobjects";

import { ReInitiate } from "./shared/common/reInitiate";
import { ReMigrationButton } from "./shared/common/reMigrationBttn";
import { ScheduleTimeBtn } from './shared/common/schedule-time-btn';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RunBtn } from './shared/common/runBtn';
import { ReportPath } from './shared/common/reportPath';
import { DynamicscreendFileDownload } from './shared/common/dynamicscreend-fileownload-link.component';
import { Reportdownload } from './shared/common/reportdownload';
import { TemplateReportButtons } from './shared/common/template-report-buttons';
import { CommonDownloadLinkFromS3 } from './shared/common/commonDownloadLinkFromS3';
import { ReportFacetComponent } from './user/report-facet/report-facet.component';
import { Interceptor } from './common/Interceptor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VaultBinderReferenceComponent } from './vault-binder-reference/vault-binder-reference.component';
import { MsalModule, MsalService, MSAL_INSTANCE, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { EtlMigrationPopupComponent } from './user/etl-migration-popup/etl-migration-popup.component';
import { EditButton } from './shared/common/Editbutton';
import { Downloadbutton } from './shared/common/downloadbutton';
import { ViewButton } from './shared/common/view-button';
import { BuildButton } from './shared/common/BuildButton';
import { DownloadLogFile } from './shared/common/DownloadLogFileButton';
import { CheckoutFiles } from './shared/common/CheckoutFiles';
import { VaultobjectPopupScreenExtractiongrpComponent } from './user/vaultobject-popup-screen-extractiongrp/vaultobject-popup-screen-extractiongrp.component';
import { AudittrailHtmlReportComponent } from './user/audittrail-html-report/audittrail-html-report.component';

// this function is used for azure microsoft login this function contains client details
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    // https://login.microsoftonline.com/common/
    // https://login.microsoftonline.com/9188040d-6c67-4c5b-b112-36a304b66dad/v2.0
    auth: {
      // clientId: 'd5f18c94-7f04-4586-81b0-af02f9691fb2',
      clientId: '9d1a5cae-620f-4315-8cd6-a21e17b4860b',
      authority: 'https://login.microsoftonline.com/2c2d760c-c4eb-467c-8fe7-79ef18d1468e',
      redirectUri: location.origin
    },
    cache: {
      cacheLocation: "localStorage"
    }
  });
}



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsOpacity: 0.3,
  blur: 3,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  fgsColor: 'red',
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 0, // progress bar thickness
  overlayColor: 'rgba(40,40,40,.3)',
};

@NgModule({
  declarations: [
    AppComponent,
    Actionbutton,
    EditButton,
    UploadButton,
    Deletebutton,
    FiledownloadComponent,
    publishButton,
    ActionCheckBox,
    Radiobutton,
    Schedulebutton,
    ImageviewPopupComponent,
    ImageViewInPopupComponent,
    ReportTemplateButton,
    UploadToVault,
    ResumeLoadButton,
    GenerateReport,
    GeneratedSummaryReport,
    FileUploadButton,
    EtlActionCheckBox,
    Migrationtracelogs,
    ValidationReportDownload,
    SummaryReportDownload,
    GeneratedSplitReport,
    GenerateButton,
    LoadToVault,
    DocAttachmentFilePath,
    LinkToCasesButton,
    DownloadMappingFile,
    RemoveButton,
    CustomCheckBoxButton,
    AttachmentButton,
    DownloadTestResult,
    InputFileDownloadButton,
    VaultObjectRefereshButton,
    PopupButton,
    DownloadGlobalObjects,
    ReInitiate,
    ReMigrationButton,
    ScheduleTimeBtn,
    RunBtn,
    ReportPath,
    DynamicscreendFileDownload,
    Reportdownload,
    TemplateReportButtons,
    CommonDownloadLinkFromS3,
    ReportFacetComponent,
    VaultBinderReferenceComponent,
    EtlMigrationPopupComponent,
    Downloadbutton,
    ViewButton,
    BuildButton,
    DownloadLogFile,
    CheckoutFiles,
    AudittrailHtmlReportComponent


  ],
  imports: [

    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RouterModule.forRoot(rootRouterConfig, { relativeLinkResolution: 'legacy' }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AgGridModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TreeviewModule.forRoot(),
    FlexLayoutModule,
    MatSlideToggleModule,

  ],
  providers: [
    MsalModule,
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    MsalBroadcastService

  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]

})
export class AppModule { }
