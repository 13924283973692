
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const requiredHeaders = {
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
      'X-Content-Type-Options': 'nosniff',
      'Content-Security-Policy': "default-src 'self'",
      'X-Frame-Options': 'DENY',
      'Access-Control-Allow-Origin': '*',
    };
// Check if the request contains all the required headers
const missingHeaders = Object.keys(requiredHeaders).filter(
  key => req.headers.get(key) !== requiredHeaders[key]
);

// If there are missing headers, add them to the request
if (missingHeaders.length > 0) {
  const headers = {};
  Object.keys(requiredHeaders).forEach(key => {
    if (!req.headers.has(key)) {
      headers[key] = requiredHeaders[key];
    }
  });
  const updatedRequest = req.clone({
    setHeaders: headers
  });
  //modified request sent
  return next.handle(updatedRequest);
}

// Otherwise, proceed with the original request
return next.handle(req);
}    
  
  
}
