

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-filedownload',
  template: `
  <button title="Download" (click)="downloadCase($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>`
})
export class Downloadbutton implements ICellRendererAngularComp{
    params;
    agInit(params): void {
        this.params = params;
      }
    refresh(params: ICellRendererParams<any, any>): boolean {
        throw new Error('Method not implemented.');
    }

    downloadCase($event) {
       console.log(this.params)
        if (this.params.download instanceof Function) {
            console.log("hello")
          // put anything into params u want pass into parents component
          const params = {
            event: $event,
            rowData: this.params.node.data
            // ...something
          }
          console.log(params)
          this.params.download(params);
    
        }
      }
}
