import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-filedownload',
  template: `
  <button [title]="btnTitle" (click)="docdownload($event);" [disabled]="isdisable" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>`
})
export class FiledownloadComponent implements ICellRendererAngularComp {

  params;
  label: string;
  isdisable:boolean=false;
  btnTitle="Download"

  agInit(params): void {
    if(params.colDef.headerName== "Download Transformation Mapping Template"){
      this.btnTitle="Download Transformation Mapping Template";
    }
    this.params = params;
    if(params.colDef.headerName== 'Download Mapping file Validation Report'){
      this.isdisable=true;
      if(params.data.migrationType=="Data Migration"){
        this.isdisable=false;
      }
      

    }
  
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  docdownload($event) {
    console.log("hello")
    console.log(this.params)
    if (this.params.docdownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.docdownload(params);

    }
  }
}
