import { Injectable, Injector } from '@angular/core';
import { isObject, isString } from 'util';

@Injectable({
    providedIn: 'root'
})

export class FormFieldsTrimAndClean {

    // Method for all screen except Shipment info screen
    trimFormfieldsData(obj) {

        var keys = Object.keys(obj);

        for (var i = 0; i < keys.length; i++) {

            if (obj[keys[i]] != null) {

                obj[keys[i]] = obj[keys[i]].toString().trim();

            }
        }

        return obj;
    }

    trimFormfieldsDataForField(obj) {

        var keys = Object.keys(obj);

        for (var i = 0; i < keys.length; i++) {

            if (obj[keys[i]] != null) {

                if (keys[i] == "agGridColumnShow" || keys[i] == "formColumnShow" || keys[i] == "isRequired" || keys[i] == "isUnique") {

                }

                else {

                    obj[keys[i]] = obj[keys[i]].toString().trim();

                }

            }
        }

        return obj;
    }

    trimScreenCreatorFields(obj) {

        var keys = Object.keys(obj);

        for (var i = 0; i < keys.length; i++) {

            if (obj[keys[i]] != null) {

                if (keys[i] == "numberOfColumnsInRow" || keys[i] == "multiTenant") {

                }

                else {

                    obj[keys[i]] = obj[keys[i]].toString().trim();

                }

            }
        }

        return obj;
    }

    clean(obj) {
        var propNames = Object.getOwnPropertyNames(obj);
        for (var i = 0; i < propNames.length; i++) {
            var propName = propNames[i];
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
                delete obj[propName];
            }
        }
        return obj;
    }
}