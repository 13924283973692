import { HttpHeaders,HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApi } from 'src/app/shared/RestApi/restApi';
import {stringify} from "querystring";

interface etlMigration {
  error: boolean,
  data: any,
  statusMessage:any
}

@Injectable({
  providedIn: 'root'
})
export class EtlService {

  private headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(private http: HttpClient,private restApi:RestApi) { }

  transformMigration(data){
    return this.http.post<etlMigration>(this.restApi.TRANSFORMATION,JSON.stringify(data),{ headers: this.headers })
  }

  loadToVault(dnsName,dnsId,setids,migrationType){
    return this.http.post<etlMigration>(this.restApi.LOADING+"/"+dnsName+"/"+dnsId+ "/"+migrationType+"/"+setids,{ headers: this.headers })
  }

  // added objectname parameter
  resumeLoadToVault(dnsName,dnsId,setid,migrationType,objectName){
    return this.http.post<etlMigration>(this.restApi.RESUMELOADING+"/"+dnsName+"/"+dnsId+ "/"+migrationType+"/"+setid+"/" +objectName,{ headers: this.headers })
  }

  resumeLoadparallel(migrationTraceId){
    return this.http.get<etlMigration>(this.restApi.RESUMELOADFORPARALLEL+"/"+migrationTraceId,{ headers: this.headers })
  }

  insertetobtask(dnsName,dnsId,data){
    return this.http.post<etlMigration>(this.restApi.INSERTETOBTASK+"/"+dnsName+"/"+dnsId+"/insert",JSON.stringify(data),{ headers: this.headers })
  }

  insertetobmappingfile(dnsName,mappingfile){
    return this.http.post<etlMigration>(this.restApi.INSERTMAPPINGFILEETOB+"/"+dnsName,JSON.stringify(mappingfile),{ headers: this.headers })

  }

  retrieveMigrationExtracttrace(dnsId,migrationId,migrationType){
    return this.http.get<any>(this.restApi.GET_MIGRATIONTRACE_DATA + "/"+dnsId+ "/" + migrationId+ "/" + migrationType , { headers: this.headers });
  }

  // Service call to retrieve Deffered sets. Written by kalpesh
  retrieveDefferedSets(dnsId){
    return this.http.get<any>(this.restApi.DATABASE_NAMES+"/"+dnsId, { headers: this.headers })
  }

  // Service call to retrieve Deffered objects. Written by kalpesh
  retrieveDefferedObjects(dnsId){
    return this.http.get<any>(this.restApi.DEFFEREDOBJECTS+"/"+dnsId, { headers: this.headers })
  }

  // service call for retrieveing objects and display on dropdown written by kalpesh
  retrieveUpdateCaseObjects(dnsId){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/loadobjectsmstr" + "/tenantid" + "/"+dnsId , { headers: this.headers });
  }

  retrieveMigrationTransformtrace(dnsId){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/migrationtrace" + "/dnsid" + "/"+dnsId , { headers: this.headers });
  }

  retrieveLoaderExtractTrace(dnsId){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/migrationtrace" + "/dnsid" + "/"+dnsId , { headers: this.headers });
  }

  // using vaultLoader service instaed of commonretrieve service
  retrieveVaultInstruction(migrationtraceId){
    return this.http.get<any>(this.restApi.LOADING + "/instruction" + "/"+migrationtraceId , { headers: this.headers });
  }

  retrieve(){
    return this.http.get<any>(this.restApi.RETRIEVEDATA, { headers: this.headers });
  }
  // Change request for comment field. sending dnsId, migrationType and comment in JSON. Changes made by kalpesh.
  startmigration(startmigrationData){
    return this.http.post<any>(this.restApi.STARTMIGRATION, JSON.stringify(startmigrationData) , {headers: this.headers });
  }
  retrieveMigrationDatalog(dnsId) {
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_FORETL + "/" +dnsId, { headers: this.headers });

  }

  retrieveJsuiteProperty(dnsId, propertyValue) {
    return this.http.get<any>(this.restApi.RETRIEVEJSUITEPROPERTIES + "/" +dnsId+ "/"+ propertyValue, { headers: this.headers });
  }

  abortmigration(dnsId, migrationType,migrationstatus,migrationid){
    return this.http.put<any>(this.restApi.ABORTMIGRATION +"/"+dnsId+ "/"+ migrationType + "/"+migrationstatus+"/"+migrationid, {headers: this.headers });
  }

  uploadMappingFile(mappingFile,dnsName){
    const formData = new FormData();
  
    // Create a Blob from statevaluechange.fileData
    const fileBlob = new Blob([mappingFile.fileData]);
  
    // Append the 'file' part
    formData.append('file', fileBlob, mappingFile.fileName); 
    // Clear the 'fileData' to avoid duplication
    mappingFile.fileData = null; 
  
    // Append the 'data' part as JSON
    formData.append('data', JSON.stringify(mappingFile));
    
    return this.http.post<etlMigration>(this.restApi.UPLOADMAPPINGFILE+"/"+dnsName,formData)
  }

  // service call to upload file to the s3 written by kalpesh
  uploadObjectFile(objectFile){
    return this.http.post<etlMigration>(this.restApi.UPLOADOBJECTFILE,JSON.stringify(objectFile),{ headers: this.headers })
  }

  // service call for updating case data written by kalpesh
  updateCaseData(migrationTraceId){
    return this.http.post<etlMigration>(this.restApi.UPDATECASEDATA+"/"+migrationTraceId,{ headers: this.headers })
  }

  // service call for deleting uploaded file from s3 written by kalpesh
  deleteCaseData(vaultLoadInstructionId){
    return this.http.delete<any>(this.restApi.DELETECASEDATA +"/"+vaultLoadInstructionId);
  }

  runE2BMigration(data){
    return this.http.post<etlMigration>(this.restApi.RUNMIGRATIONE2B,JSON.stringify(data),{ headers: this.headers })
  }

  retrieveetobobjects(dnsId){
    return this.http.get<any>(this.restApi.RETRIEVEETOOBJECTS +"/"+dnsId, { headers: this.headers });
  }

  retrieveetobStageFilePath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/E2B-Stage-Files-Path" , { headers: this.headers });
  }

  retrieveLiteratureStageFilePath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/Literature-Stage-Files-Path" , { headers: this.headers });
  }

  retrieveAttachmentStageFilePath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/Attachments-Stage-Files-Path" , { headers: this.headers });
  }

  retrieveetobFTPPath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/E2B-Ftp-Path" , { headers: this.headers });
  }

  retrieveLiteratureFTPPath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/Literature-Ftp-Path" , { headers: this.headers });
  }

  retrieveAttachmentFTPPath(){
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/lookupvalue" + "/lookupcode" + "/Attachment-Ftp-Path" , { headers: this.headers });
  }

  retriveMappingFile(dnsName){
    return this.http.get<any>(this.restApi.RETRIEVEMAPPINGFILE+"/"+dnsName, { headers: this.headers });
  }

  downloadMappingFile(migrationId) : Observable<Blob> {
    return this.http.get(this.restApi.DOWNLOADMAPPINGFILE+"/"+migrationId, { headers: this.headers,  responseType: 'blob' });
  }

  downloadValidationReportFile(dnsName,path):Observable<Blob>{
    console.log(path)
    return this.http.get(this.restApi.DOWNLOADREPORTVALIDATIONFILE+"/"+dnsName+"/"+path, { headers: this.headers,  responseType: 'blob' });
  }

  downloadSummaryeportFile(dnsName,path){
    return this.http.get<any>(this.restApi.DOWNLOADREPORTSUMMARYFILE+"/"+dnsName+"/"+path, { headers: this.headers });
  }

  generateSummaryReport(isfileuploded,isQuantitativeFileUploaded,migrationId,){
    return this.http.put<any>(this.restApi.GENERATESUMMARYREPORT +"/"+isfileuploded+"/"+isQuantitativeFileUploaded+"/"+migrationId , {headers: this.headers });
  }

  completemigration(dnsId){

    return this.http.put<any>(this.restApi.ABORTMIGRATION +"/"+dnsId + "/Completed" , {headers: this.headers });

  }

  downloadSourceFile(dnsName,logpath){
    return this.http.get<any>(this.restApi.DOWNLODSOURCEFILE+"/"+dnsName+"/"+logpath, { headers: this.headers });
  }


  uploadingSourceFile(sourceFile,dnsName){
    return this.http.post<any>(this.restApi.UPLOADSOURCEFILE+"/"+dnsName,sourceFile);
  }

  uploadingSourceFileparallel(sourceFile,dnsName){
    return this.http.post<any>(this.restApi.UPLOADSOURCEFILEPARALLEL+"/"+dnsName,sourceFile);
  }


  downloadExtractFile(dnsName,logpath){
    return this.http.get<any>(this.restApi.DOWNLOADEXTRACTFILE+"/"+dnsName+"/"+logpath, { headers: this.headers });
  }

  reExtractLibraryObject(dnsName, objectname){

    return this.http.post<any>(this.restApi.REEXTRACTLIBRARYOBJECT+"/"+dnsName,JSON.stringify(objectname), {headers: this.headers });

  }


  retrieveVaultObjects(){

    return this.http.get<any>(this.restApi.RETRIEVEVAULTOBJECTS, { headers: this.headers });

  }

  public download_test(supportingDoc): Observable<Blob> {

    return this.http.get(supportingDoc, { responseType: 'blob' });
  }

  public download_zip(supportingDoc): Observable<ArrayBuffer> {

    return this.http.get(supportingDoc, { responseType:'arraybuffer',headers: this.headers });
  }

  public insertJsuiteMigrationParent(migrationParent){
    return this.http.post<etlMigration>(this.restApi.INSERTJSUITEMIGRATIONPARENT,JSON.stringify(migrationParent),{ headers: this.headers })
  }

  insertCsvToS3(sourceFile){
    return this.http.post<any>(this.restApi.INSERT_CSV_FILE_TO_S3,JSON.stringify(sourceFile), { headers: this.headers });
  }

  generateSplitReport(migrationId){
    return this.http.put<any>(this.restApi.GENERATESPLITREPORT +"/"+migrationId , {headers: this.headers });
  }
  retrievingAttachmentLoadDetails(migrationTraceId){
    return this.http.get<any>(this.restApi.RETRIEVINGATTACHMENTLOADDETAILS+"/"+migrationTraceId, { headers: this.headers });
  }

  updateLoadReadyStatus(attachmentLoadDetailsObj: any) {
    var option
    if (attachmentLoadDetailsObj['LoadToVault'] === ""){
    option= {headers: this.headers}
    }
    else{
       option = {headers: this.headers,  responseType: 'arraybuffer'}
    }

    // @ts-ignore
    return this.http.put<any>(this.restApi.UPDATELOADREADYSTATUS ,JSON.stringify(attachmentLoadDetailsObj) ,option);

  }
  retrieveSingleRecordService(tableName, fieldName, fieldValue) {
    return this.http.get<any>(this.restApi.GET_TABLE_SINGLE_RECORD_URL + "/" + tableName + "/" + fieldName + "/" + fieldValue, { headers: this.headers });
  }
  uploadMappingFileForRunDetail(mappingFile){
    return this.http.post<etlMigration>(this.restApi.UPLOADMAPPINGFILE,JSON.stringify(mappingFile),{ headers: this.headers })
  }

  attachmentLinkToCase(setId,migrationId){
    return this.http.post<any>(this.restApi.ATTACHMENTLINKTOCASE+"/"+setId+"/"+migrationId, { headers: this.headers });
  }

  resumeVaultObjectExtraction(setId,migrationId,dnsId){
    return this.http.post<any>(this.restApi.RESUMEVAULTOBJECTEXTRACTION+"/"+setId+"/"+migrationId+"/"+dnsId, { headers: this.headers });

   }
  insertMigrationTraceRecords(extractionType,setIds){
    return this.http.get<etlMigration>(this.restApi.INSERTMIGRATIONTRACERECORDS + "/" + extractionType + "/" + setIds, { headers: this.headers });
  }

  reInitiateMigration(obj){
    return this.http.post<etlMigration>(this.restApi.REINITIATEMIGRATION,JSON.stringify(obj),{ headers: this.headers })
  }

  reMigrate(migrationId,setId,reMigrateStage){
    return this.http.get<etlMigration>(this.restApi.REMIGRATE + "/" + migrationId + "/" + setId + "/" + reMigrateStage , { headers: this.headers });

  }

  uploadValidateMappingFile(migrationId: any) {
    return this.http.get<any>(this.restApi.VALIDATEMAPPINGFILE+ "/" + migrationId, { headers: this.headers });
  }
  public downloadFileFromS3(s3Path): Observable<Blob> {

    return this.http.post(this.restApi.DOWNLOADFILEFROMS3, JSON.stringify(s3Path),{ headers: this.headers, responseType: 'blob' });
  }

  downloadEMRLogsFile(obj): Observable<ArrayBuffer>{
    return this.http.post<any>(this.restApi.DOWNLOADFILESANDZIP, JSON.stringify(obj), { headers: this.headers,responseType:'arraybuffer' as 'json'});
    }
  updateDeleteStatusForSet(migrationTraceId){
    return this.http.put<any>(this.restApi.DELETECASEFROMVAULT+"/"+migrationTraceId, { headers: this.headers });
  }

  getGlobalObjectsList(dnsId,migrationId){
    return this.http.get<any>(this.restApi.GETGLOBALOBJECTSLISTBYDNSID +"/"+dnsId+"/"+migrationId,{ headers: this.headers });

  }
  createGlobalObjects(objectname){

    return this.http.post<any>(this.restApi.CREATEGLOBALOBJECTS,JSON.stringify(objectname), {headers: this.headers });

  }
 //service to retrieve the migrationtrace records based on the extraction type
  retrieveMigrationGlobalTrace(dnsId,migrationId,MigrationType,extractionType){
    return this.http.get<any>(this.restApi.GET_GLOBAL_OBJECTS+"/"+dnsId+"/"+migrationId+"/"+MigrationType+"/"+extractionType,{headers:this.headers});
  }

  // This method will retrieve the ExtractionTransformationInstruction records based on the migrationtraceid
  retrieveExtractionTransformationInstructionRecords(migrationTraceId){
    return this.http.get<any>(this.restApi.RETRIEVEEXTRACTIONTRANSFORMATIONINSTRUCTION+migrationTraceId,{headers:this.headers});
  }
  // this method will retrieve the instrctions based on the activity
  retrieveInstructionByActivity(dnsId,migrationId,setId,activity){
    return this.http.get<any>(this.restApi.RETRIEVEINSTRUCTIONSBASEDONACTIVITY+dnsId+"/"+migrationId+"/"+setId+"/"+activity,{headers:this.headers});
  }
  
  retrieveSourceAndTraget(dnsId, type){
    return this.http.get<any>(this.restApi.RETRIEVE_SOURCE_AND_TARGET + "/" + dnsId + "/" + type, {headers:this.headers});
  }

  // this method will retrive the Object Group Names 
  getObjectGroupsNames(SourceDnsID, TargetDnsId){
    return this.http.get<any>(this.restApi.GET_OBJECTGROUP_NAMES + "/" + SourceDnsID + "/" + TargetDnsId, {headers:this.headers});
  }

  // this method will check the incremental load is possible or not
  reInitiateMigrationForIncrementalLoad(obj){
    return this.http.post<etlMigration>(this.restApi.REINITIATEMIGRATIONFORINCREMENTALLOAD,JSON.stringify(obj),{ headers: this.headers })
  }
 /**
 * Added By Abhishek t
 * 16-10-2023
 * This method sends a POST request to save running services for a given DNS ID.
 *
 * @param dnsId The DNS ID for which running services are to be saved.
 * @param obj The data object containing running services information.
 * @returns An HTTP observable for the POST request.
 */
saveRunningServices(dnsId, obj) {
  return this.http.post<any>(this.restApi.SAVE_RUNNING_SERVICE + "/" + dnsId, obj, { headers: this.headers });
}

/**
 * Added By Abhishek t
 * 16-10-2023
 * This method sends a GET request to retrieve running services for a given run ID.
 *
 * @param runid The run ID for which running services are to be retrieved.
 * @returns An HTTP observable for the GET request.
 */
retriveRunningServices(runid) {
  return this.http.get<any>(this.restApi.RETRIVE_RUNNING_SERVICE + "/" + runid, { headers: this.headers });
}

insertMigrationForDeletion(form:any){
  return this.http.post<any>(this.restApi.INSERT_DELETION_MIGRATION, JSON.stringify(form), { headers : this.headers });
}

}

