import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-etlActionCheckBox',
  template: `
  <mat-checkbox class="example-margin" [(ngModel)]="selectinstance" (change)="updateCheckbox($event)" [disabled]="statusComplete"></mat-checkbox>
 `
})


export class EtlActionCheckBox implements ICellRendererAngularComp {

  params:any;
  label: string;
  checkStatusComplete: any = [];
  statusComplete = false;
  selectinstance

  agInit(params): void {
    this.params = params;
    console.log(this.params);
    this.checkStatusComplete = this.params.data;
    if(this.checkStatusComplete.activity!=='Load'){
      this.checkStatusComplete.state === "Completed" ? this.statusComplete = false : this.statusComplete = true;
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  updateCheckbox($event) {
    console.log($event)
    console.log(this.params.updateCheckbox)
    if (this.params.updateCheckbox instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.updateCheckbox(params);

    }
  }

}
