<div class="topDiv" [style.background-color]="backgroundColorScreen">

    <!-- <div class="agGridOuterDiv">
        <div class="row topRowMargins headerStyle">
        </div>
    </div> -->

    <div class="headerStyle facetTitle">
        Manage Facets
    </div>

    <div class="row">
        <div style="width: 30%; margin-left: 2%;margin-top: 4%;">
          <div class="seperateDiv">
            <ag-grid-angular class="ag-theme-balham table-width-fields
              tablestyles square scroolbar-dusty-grass square thin"
              [columnDefs]="columnDefsForVaultObjects" [animateRows]="true"
              [defaultColDef]="  defaultColDef" [suppressPaginationPanel]="true"
              [rowSelection]="rowSelection" [rowHeight]="35" [headerHeight]="35"
              [enableBrowserTooltips]="true" [floatingFiltersHeight]="45"
              [enableRangeSelection]="true" [debug]="true"
              (gridReady)="onGridReadyForVaultObjects($event)"
              (cellClicked)="onCellClickedVaultObjects($event)" [hidden]="!true">
            </ag-grid-angular>
          </div>
        </div>

        <div style="width: 30%;margin-left: 1%;margin-top: 4%;">
          <div class="seperateDiv">
            <ag-grid-angular class="ag-theme-balham table-width-fields
              tablestyles square scroolbar-dusty-grass square thin"
              [columnDefs]="columnDefsForObjectAttributes" [animateRows]="true"
              [defaultColDef]="defaultColDef" [suppressPaginationPanel]="true"
              [rowSelection]="rowSelectionForObjectAttributes" [rowHeight]="35"
              [headerHeight]="35" [enableBrowserTooltips]="true"
              [floatingFiltersHeight]="45" [enableRangeSelection]="true"
              [debug]="true" [frameworkComponents]="frameworkComponents"
              (gridReady)="onGridReadyForObjectAttributes($event)"
              (rowSelected)="onRowSelectedForAttributes($event)" [hidden]="!true">
            </ag-grid-angular>
          </div>
        </div>

        <div style="font-size: 1.8rem;margin-top: 13%; margin-left: 1%;">
            <div (click)="onPushDataToSelectedFieldsGrid()">
                <i class="fas fa-step-forward" title="Move selected rows to
                  Right"></i>
              </div>
              <div (click)="onPushAllDataToSelectedFieldsGrid()">
                <i class="fas fa-forward" title="Move all rows to Right"></i>
              </div>
              <div (click)="onPopAllDataFromSelectedFieldsGrid()">
                <i class="fas fa-backward" title="Move all rows to Left"></i>
              </div>
              <div (click)="onPopDataToSelectedFieldsGrid()">
                <i class="fas fa-step-backward" title="Move selected rows to
                  Left"></i>
              </div>
        </div>

        <div style="width: 30%; margin-left: 1%; margin-top: 4%;">
            <div class="seperateDiv">
              <ag-grid-angular class="ag-theme-balham table-width-fields
                tablestyles square scroolbar-dusty-grass square thin"
                [columnDefs]="columnDefsForSelectedAttributes"
                [defaultColDef]="defaultColDef" [animateRows]="true"
                [rowSelection]="rowSelectionForObjectAttributes" [rowHeight]="35"
                [headerHeight]="35"
                (gridReady)="onGridReadyForSelectedAttributeFields($event)"
                (rowSelected)="onRowSelectedToRemove($event)" [hidden]="!true">
              </ag-grid-angular>
            </div>
        </div>
    </div>

    <div class="col-lg">
      <div class="container" style="text-align: center;">
        <div class="row topRowMargins">
          <div class="col-lg-4"></div>
          <mat-checkbox class="mat-checkbox-layout" ngDefaultControl
              [(ngModel)]="facetCreation" style="margin-top: 1.5vh;">Initiate
              Facet Creation</mat-checkbox>
          <div class="col-lg-2">
            <button i18n="@@TMRunButton" type="button"
              (click)="createFacets()"
              [style.background-color]="buttonColor"
              class="actionButton btn btn-rounded-button z-depth-1
              waves-effect waves-light text-capitalize" style="padding:
              .3rem 1.6rem;font-size: .85rem;width:100%; margin-left: 5%;">{{butttonname}}</button>
          </div>
          <div class="col-lg-2">
            <button i18n="@@TMRunButton" type="button" (click)="cancel()"
              [style.border-color]="buttonColor"
              class="cancelButton btn btn-rounded-button z-depth-1
              waves-effect waves-light text-capitalize" style="padding:
              .3rem 1.6rem;font-size: .85rem;width:100%;">Cancel</button>
          </div>
          <div class="col-lg-4">
          </div>
        </div>
      </div>
    </div>

    <div style="width: 97%; margin-left: 1%;">
      <mat-toolbar class="agGridtoperDiv card" style="
        background: rgb(228 227 230 / 41%);border-top: 1px solid #a9a9a9a8;
        border-right: 1px solid #a9a9a9a8;
        border-left: 1px solid #a9a9a9a8;">
        <label i18n="@@spmsUserReg" class="textColour">Facets</label>
        <span class="example-spacer"></span>
        <button class="refreshrunButton popup gridColour btn btn-rounded-button
          z-depth-1 waves-effect waves-light text-capitalize"
          [style.background-color]="buttonColor" title="Refresh"
          (click)="refreshRun()">
          Refresh<i style="padding: 0px 0px 0px 10px;" class="fas fa-refresh"
            title="Refresh"></i>
        </button>
        <button class="popup gridColour btn btn-rounded-button z-depth-1
          waves-effect waves-light text-capitalize"
          [style.background-color]="buttonColor" title="Download"
          (click)="onBtExport()">Download
          <i style="padding: 0px 0px 0px 10px;" class="fas fa-download
            example-icon" title="Download"></i>
        </button>
      </mat-toolbar>
    </div>
    <div style="width: 97%; margin-left: 1%;">
      <ag-grid-angular class="ag-theme-balham table-width tablestyles square
        scroolbar-dusty-grass square thin"
        [columnDefs]="columnDefsForFacets" [animateRows]="true"
        [defaultColDef]="defaultColDef"
        [suppressPaginationPanel]="true" [rowHeight]="35"
        [headerHeight]="35" [enableBrowserTooltips]="true"
        [floatingFiltersHeight]="45"
        [debug]="true"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReadyForFacets($event)" [hidden]="!true">
      </ag-grid-angular>
    </div>
</div>
