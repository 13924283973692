import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-resumeLoadButton',
  template: `
    <button (click)="reMigrationBttn($event);"
            class="btn btn-outline btn-success btn-rounded btn-sm waves-effect waves-light"  [disabled]="params.node.data.isSetIdIsNegativeFloat"
            style="padding: .3rem .8rem;margin-top:0rem">
      <i class="fas fa-repeat"></i>
    </button>`
})
export class ReMigrationButton implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  reMigrationBttn($event) {
    console.log("hello")
    if (this.params.reMigration instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        param:this.params
        // ...something
      }
      console.log(params)
      this.params.reMigration(params);

    }
  }
}
