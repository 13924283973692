import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface helpdeskDialogData {
  ngModelTemporary: any;
}
@Component({
  selector: 'app-imageview-popup',
  templateUrl: './imageview-popup.component.html',
  styleUrls: ['./imageview-popup.component.scss']
})
export class ImageviewPopupComponent implements OnInit {

  photo: any;

  constructor(public dialogref: MatDialogRef<ImageviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: helpdeskDialogData, public sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.photo = this.data.ngModelTemporary;

    console.log(this.photo);

  }

}
