import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-filedownload',
  template: `
    <a (click)="openInVault($event)" style="color: cornflowerblue;">{{fileName}}</a>`
})

export class VaultBinderReferenceComponent implements ICellRendererAngularComp {


  fileName:any= "Open in Vault";
    params:any;
    sessionid:any;
    documentId:any;

  constructor() { }

  agInit(params): void {
    this.params= params; 
    console.log(params)


    if(params.data.execStatus === "Passed" || params.data.execStatus==="Failed"){
        this.fileName= "Open in Vault";
    }else{
      this.fileName = "";
    }
}

refresh(params: ICellRendererParams<any, any>): boolean {
  return true;
}

openInVault($event){
  console.log(this.params.data)
  var binderId = this.params.data.binderId
  console.log(localStorage.getItem("vaultDnsname"))
    let dnsName =localStorage.getItem("vaultDnsname");
   let vaultDocUrl ="https://"+dnsName+"/ui/#doc_info/+"+binderId;
      window.open(vaultDocUrl, '_blank');
  
  }
}
