
<div class="topDiv" [style.background-color]="backgroundColorScreen">
    <div style="margin-top:3%">
        <div class="vaultDataloadHeadDiv">
            <h4>Audit Trail Report</h4>
        </div>


    </div>

    <div  class="agGridOuterDiv">
      <div>
        <mat-toolbar class="agGridtoperDiv card" style="background: rgb(228 227 230 / 41%);border-top: 1px solid #a9a9a9a8; border-right: 1px solid #a9a9a9a8; border-left: 1px solid #a9a9a9a8;">
          <label i18n="@@spmsUserReg" class="textColour">Audit Trail Report</label>
          <div style="display: flex; margin-left: 40px;">
            <div style="margin-top: 4px;" >
                <mat-radio-group [(ngModel)]="dbNameValue">
                  <mat-radio-button
                    style="font-size: medium"
                    value="ARISg"
                    (click)="select('ARISg')"
                    >ARISg</mat-radio-button
                  >
                  <mat-radio-button
                    style="font-size: medium; margin-left: 25px"
                    value="ARISj"
                    (click)="select('ARISj')"
                    >ARISj</mat-radio-button
                  >
                  <mat-radio-button
                  style="font-size: medium; margin-left: 25px"
                  value="Delta"
                  (click)="select('DELTA')"
                  >Delta</mat-radio-button
                >
                </mat-radio-group>    
              </div>
              <div class="input-container" (click)="$event.stopPropagation()">
                <input class="custom-input" type="text" [(ngModel)]="caseNumberValue"  placeholder="Case Number" [disabled]="!dbSelected">
              </div>
              <button style="width: 70px;margin-left: 10px;" id="run" class="popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize" [style.background-color]="backgroundColorButton" title="Search" (click)="onSearch(caseNumberValue,dbNameValue)" >Search
                     
              </button>
        </div>
          <span class="example-spacer"></span>


          <button
            class="popup gridColour btn btn-rounded-button z-depth-1 waves-effect waves-light text-capitalize"
            [style.background-color]="backgroundColorButton"
            title="Download"
            (click)="onBtExport()"
          >
            Download
            <em
              style="padding: 0px 0px 0px 10px"
              class="fas fa-download example-icon"
              title="Download"
            ></em>
          </button>
          <div class="insertPageNoDiv">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@Targetpageno">Page No</mat-label>
              <input
                matInput
                type="text"
                (input)="onSearchChange($event.target.value)"
              />
            </mat-form-field>
          </div>
          <div class="selectRecordsDiv" style="width: 9.5%">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label i18n="@@Targetrecords" style="color: black"
                >Records</mat-label
              >
              <mat-select
                [(ngModel)]="pageSize"
                (ngModelChange)="onPageSizeChanged(value)"
              >
                <mat-option value="10" selected>10</mat-option>
                <mat-option value="200">200</mat-option>
                <mat-option value="300">300</mat-option>
                <mat-option value="400">400</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="card pagination paginationFirstButton paginationFirstlastButton"
          >
            <em
              class="fas fa-angle-double-left waves-effect waves-light"
              (click)="onBtFirst()"
            ></em>
          </div>
          <div class="card pagination paginationButtons btn-rounded">
            <ul>
              <li class="paginationliicon">
                <em
                  class="fas fa-angle-left waves-effect waves-light"
                  (click)="onBtPrevious()"
                ></em>
              </li>
              <li>{{ currentPage }}/{{ totalPages }}</li>
              <li class="paginationliicon" style="padding: 0">
                <em
                  class="fas fa-angle-right waves-effect waves-light"
                  (click)="onBtNext()"
                ></em>
              </li>
            </ul>
          </div>
          <div
            class="card pagination paginationLastButton paginationFirstlastButton"
          >
            <em
              class="fas fa-angle-double-right waves-effect waves-light"
              (click)="onBtLast()"
            ></em>
          </div>
        </mat-toolbar>
      </div>
  
      <ag-grid-angular
        class="ag-theme-balham table-width tablestyles square scroolbar-dusty-grass square thin"
        [columnDefs]="columnDefs"
        [animateRows]="true"
        [pagination]="true"
    
        [defaultColDef]="defaultColDef"
        [suppressPaginationPanel]="true"
        [rowHeight]="35"
        [headerHeight]="35"
        [enableBrowserTooltips]="true"
        [floatingFiltersHeight]="45"
        [paginationPageSize]="paginationPageSize"
        [paginationNumberFormatter]="paginationNumberFormatter"
        [enableRangeSelection]="true"
        [frameworkComponents]="frameworkComponents"
        [debug]="true"
        [rowClassRules]="rowClassRules"
        (paginationChanged)="onPaginationChangedReturn()"
        (gridReady)="onGridReady($event)"
        [hidden]="!true"
      ></ag-grid-angular>
    </div>
  </div>
  