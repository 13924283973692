import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-filedownload',
  template: `
  <button title="Transformation Summary Report" (click)="transformsummaryReport($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>
  <button title="Aggregation Summary Report" (click)="aggregationsummaryReport($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>`
})
export class SummaryReportDownload implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    console.log(this.params)
   // this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  transformsummaryReport($event) {
    console.log("hello")
    if (this.params.transformsummaryReport instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.transformsummaryReport(params);

    }
  }

  aggregationsummaryReport($event) {
    console.log("hello")
    if (this.params.aggregationsummaryReport instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.aggregationsummaryReport(params);

    }
  }
}
