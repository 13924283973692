import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkout',
  template: `
    <button title="Download" [disabled]="NotContainFilePath" (click)="checkoutFiles($event);"
            class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
      <i class="fas fa-download"></i>
    </button>`
})
export class CheckoutFiles implements ICellRendererAngularComp {

  params;
  label: string;
  NotContainFilePath = true

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    let filePath = this.params.value
    console.log(filePath)
    if (filePath != '' && filePath != null && filePath != undefined) {
      this.NotContainFilePath = false
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  checkoutFiles($event) {
    console.log("hi")
    if (this.params.docdownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        fieldValue:this.params.value
        // ...something
      }
      console.log($event)
      console.log(params)
      this.params.docdownload(params);

    }
  }
}
