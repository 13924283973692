import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { log } from 'console';

@Component({
  selector: 'app-downloadlogfile',
  template: `
  <button title={{downloadlogFile}} [disabled]="NotContainFilePath" (click)="downloadlogfile($event);"
            class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
      <i class="fas fa-download"></i>
    </button>`
})
export class DownloadLogFile implements ICellRendererAngularComp {

  params;
  label: string;
  migrationId
  downloadlogFile
  NotContainFilePath = true

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params);
    var downloadlogFile = this.params.data.logFile
    console.log(downloadlogFile);
    if (downloadlogFile == null || downloadlogFile == undefined || downloadlogFile == ''){
      // this.NotContainFilePath = false
    }else {
      this.NotContainFilePath = false
      this.downloadlogFile = downloadlogFile.split('/').pop()
      console.log(this.downloadlogFile);

    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  downloadlogfile($event) {
    if (this.params.downloadlogfile instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      console.log($event)
      this.params.downloadlogfile(params);

    }
  }
}
