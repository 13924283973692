import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommonService} from '../services/common.service';
import { constants } from 'src/app/shared/common/contants';

@Component({
  selector: 'app-filedownload',
  template: `
    <a (click)="download($event)" style="color: cornflowerblue;text-decoration: underline;">{{fileName}}</a>`
})
export class CommonDownloadLinkFromS3 implements ICellRendererAngularComp {

  params;
  fileName
  label: string;
  NotContainFilePath = true
  constructor(private spinner: NgxUiLoaderService,private snackbar: MatSnackBar,private commonService:CommonService,private constants:constants) {
  }

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    let s3FilePath = this.params.value
    if (s3FilePath != '' && s3FilePath != null) {
       this.fileName = s3FilePath.split('/').pop()
      this.NotContainFilePath = false
    }
  }

  refresh(params?: any): boolean {
    return true;
  }
  s3FilePath
  download($event) {

    this.s3FilePath = this.params.value

    if (this.s3FilePath === undefined || this.s3FilePath === "" || this.s3FilePath === null) {

      this.snackbar.open(this.constants.fileNotFound, "OK", {duration: 5000});

    } else {
      let data = {
        s3Path: this.s3FilePath,
      };
      this.spinner.start();
      this.commonService.downloadFileFromS3(data).subscribe(x => {
        this.spinner.stop()
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        console.log(x)
        const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(x)
        if (responseData) {
          this.snackbar.open(this.constants.fileNotFound, "OK", {duration: 5000});

        } else {
          console.log(x)
          var newBlob = new Blob([x]);
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   window.navigator.msSaveOrOpenBlob(newBlob);
          //   return;
          // }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          console.log(data)

          var link = document.createElement('a');
          link.href = data;
          link.download = this.fileName;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

        }

      })

      this.spinner.stop();
    }

  }
}
