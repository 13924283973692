import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
@Component({
  selector: 'app-runbtnTime',
  template: `
  <button title="Run" (click)="run($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:#d71920">
    <i class="fas fa-play"></i>
  </button>`
})
export class RunBtn implements ICellRendererAngularComp {

  params;
  label: string;

  backgroundColorValue: string;



  agInit(params): void {
    this.params = params;

    console.log(this.params.value);

    if (this.params.value == null) {

      this.backgroundColorValue = "#F4C430";

    }

    else {

      this.backgroundColorValue = "green";

    }

    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }



  run($event) {

    console.log("$event");

    console.log($event);

    if (this.params.run instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.run(params);

    }
  }



}


