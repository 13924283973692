import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-generatedMappingFile',
  template: `
    <a (click)="downloadInputFile($event)"
       style="color: cornflowerblue;text-decoration: underline;">{{downloadInputFiles}}</a>`
})
export class InputFileDownloadButton implements ICellRendererAngularComp {

  params;
  label: string;
  migrationId
  downloadInputFiles
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params);
    this.migrationId=this.params.data.migrationId;
    var downloadInputFile = this.params.data.inputFile
    if (downloadInputFile == null || downloadInputFile == undefined || downloadInputFile == ''){

    }else {
      this.downloadInputFiles = 'Download'
    }

    console.log(this.migrationId);
  }

  refresh(params?: any): boolean {
    return true;
  }

  downloadInputFile($event) {
    console.log("hello")
    if (this.params.downloadInputFile instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.downloadInputFile(params);

    }
  }
}
