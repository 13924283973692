import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-buttonaction',
  template: `
    <button (click)="button($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem;background:#F4C430">
  <i class="fas fa-external-link-alt"></i>
    </button>`
})

export class PopupButton implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  button($event) {
    if (this.params.button instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.button(params);

    }
  }
}
