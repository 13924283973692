import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface imageDialogData {
  image: any;
}

@Component({
  selector: 'app-image-view-in-popup',
  templateUrl: './image-view-in-popup.component.html',
  styleUrls: ['./image-view-in-popup.component.scss']
})
export class ImageViewInPopupComponent implements OnInit {

  constructor(public dialogref: MatDialogRef<ImageViewInPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: imageDialogData, public sanitizer: DomSanitizer) { }

  image: any;

  ngOnInit() {
    this.image = this.data.image;
  }

}
