import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-action',
  template: `
  <button title="Rerun" (click)="uploadtovault($event);" class="btn btn-outline btn-success btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#ff5722">
  <i class="fas fa-play-circle"></i>
  </button>
  <button title="Save" (click)="downloadcsv($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#473bff">
  <i class="fas fa-download"></i>
  </button>
  <button title="Analysis" (click)="redirecttoQuickSight($event);" class="btn btn-outline btn-rounded btn-sm waves-effect waves-light"  style="padding: .3rem .8rem;margin-top:0rem;background:#00bcd4">
  <i class="fas fa-industry"></i>
  </button>`
})

export class UploadToVault implements ICellRendererAngularComp {

  
  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  uploadtovault($event) {
    if (this.params.uploadtovault instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.uploadtovault(params);

    }
  }

  downloadcsv($event) {
    if (this.params.download instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.download(params);

    }
  }

  redirecttoQuickSight($event){
    console.log($event)
    if (this.params.redirecttoQuickSight instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.redirecttoQuickSight(params);

    }
  }
}