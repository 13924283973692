import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { LoginService } from './shared/services/login.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { filter } from "rxjs";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'target';
  constructor(private msalService:MsalService,
    private loginService: LoginService,private msalBroadcastService:MsalBroadcastService){

  }
  ngOnInit() {
    console.log(this.msalService.instance.getActiveAccount());
    this.msalService.instance.handleRedirectPromise().then( res => {
      if (res != null && res.account != null) {
        console.log(res);
        this.msalService.instance.setActiveAccount(res.account)
        this.getJWTforMicrosoftLogin()
      }
    })
    this.msalBroadcastService.msalSubject$.pipe(
      filter((message:EventMessage)=>message.eventType===EventType.LOGOUT_FAILURE)
    ).subscribe((message:EventMessage)=>{
      // const authResult=message.payload as AuthenticationResult
      // this.msalService.instance.setActiveAccount(authResult.account)
      // this.getJWTforMicrosoftLogin()
      console.log(message)
    })
  }
  getJWTforMicrosoftLogin(){
    const activeAccount=this.msalService.instance.getActiveAccount();
    // activeAccount.username
    this.loginService.microsoftLogin(activeAccount.username)
  }
}
