import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-fileUploadButton',
  template: `
  <div class="file-field">
  <a class="btn btn-outline btn-rounded btn-primary btn-sm waves-effect waves-light" style="padding: 7px 13px;
  display: grid;margin-top:5px;" [class.disabled]="params.node.data.isLoadActionDelete">
      <i class="fas fa-upload mt-0" style="text-rendering: auto;color:white;"></i>
      <input type="file" id="imageupload" name="imageupload" (change)="uploadSourceFile($event)" [disabled]="params.node.data.isLoadActionDelete">
  </a>
</div>`
})
export class FileUploadButton implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  uploadSourceFile($event) {
    console.log("hello")
    console.log(this.params);
    
    if (this.params.uploadSourceFile instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.uploadSourceFile(params);

    }
    if (this.params.uploadExcelFile instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.uploadExcelFile(params);

    }
  }
}
