import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { RestApi } from '../RestApi/restApi';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as CryptoJS from 'crypto-js';
import { CommonService } from "src/app/shared/services/common.service";
import { constants } from "src/app/shared/common/contants"
import { MsalService } from '@azure/msal-angular';

//import {AsignUserRoleService} from './asign-user-role.service';
//import Amplify, { Auth } from 'aws-amplify';
//import { environment } from '../../../environments/environment';

// interface to get from the response
interface myData {
  message: any,
  error: boolean,
  token: string;
  username: string;
  usertype: any,
  details: any,
  users: any,
  photo: any,
  jswPeoples: any,
  residentDetails: any,
  addressDetailDto: any,
  statusMessage: any,
  data: any,
  defaultVaultId:number,
  vaultIds:any,
  dnsName:number
  vaultDnsname:string
}
interface veevaVault {
  sessionId: string;
  userId: string;
  vaultId: any,
  responseStatus: String,
}

interface response{
  statusMessage: any,
  error: boolean,
  data:any
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private headers = new HttpHeaders({ "Content-Type": "application/json" });
  api = new RestApi();
  //public loggedIn: BehaviorSubject<boolean>;
  users
  veevares:any
  user
  encrptKey = "123456$#@$^@1ERF";
  //password Encrypt
  encrpt(keys, value) {

    // var key = CryptoJS.enc.Utf8.parse(keys);

    // console.log(key);

    // var iv = CryptoJS.enc.Utf8.parse(keys);

    // console.log(iv);

    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    //   });

      var encrypted = CryptoJS.AES.encrypt(value, this.encrptKey);
encrypted = encrypted.toString();
console.log("Cipher text: " + encrypted);

    console.log(encrypted);

    return encrypted.toString();

  }

  getArgHeaders(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      })
    };
    return httpOptions;
  }

  //The method is use for decrypt the value.
  decrypt(keys, value) {

    var key = CryptoJS.enc.Utf8.parse(keys);

    var iv = CryptoJS.enc.Utf8.parse(keys);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);

  }

  //Amol (16/1/2020) Decrypt password
  passwordDecrypt(password) {

    var decrypted = this.decrypt(
      this.encrptKey,
      password
    );

    console.log(decrypted);

    var decodePassword = atob(decrypted);

    console.log(decodePassword);

    return decodePassword;

  }

  passwordDecrypted(password) {
    console.log(password);
    
        const decrypted = CryptoJS.AES.decrypt(password, this.encrptKey);
      return decrypted.toString(CryptoJS.enc.Utf8);
  }

  constructor(private httpClient: HttpClient, private rest: RestApi, private localStorageSettings: LocalStorageSettingsService, private snackbar: MatSnackBar, private router: Router, private spinner: NgxUiLoaderService, private commonService: CommonService, private constant: constants, private msalService:MsalService) {
   // Amplify.configure(environment.amplify);
    //this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  checkLogin(user: User) {

  }

  //  /** signup */
  //  public signUp(email, password): Observable<any> {
  //   return fromPromise(Auth.signUp(email, password));
  // }




  // api call for login service
//   login(login) {
//     console.log(login)
//   const httpBody = new HttpParams()
//   .set('username', login.userName)
//   .set('password', login.password);
//   console.log(httpBody)

// return this.httpClient.post<veevaVault>(this.rest.LOGIN_VEEVA_URL, httpBody, this.getArgHeaders());

//   }

  loginUser(login,env){
    if(env === false){
      return this.httpClient.post<myData>(this.rest.LOGIN_URL, JSON.stringify(login), { headers: this.headers });
    }else{
    return this.httpClient.post<myData>(this.rest.VEEVALOGIN_URL, JSON.stringify(login), { headers: this.headers });
    }
  }

  msalLogin(username,loginType){
    return this.httpClient.get<myData>(this.rest.MICROSOFT_LOGIN_URL+"/"+username+"/"+loginType,{ headers: this.headers })
  }

    // if user already logged in , this method will replace the user logged in
    loginReplace(login,env) {
      if(env === false){
        return this.httpClient.post<myData>(this.rest.loginReplace_URL, JSON.stringify(login), { headers: this.headers });
      }else{
      return this.httpClient.post<myData>(this.rest.veevaLoginReplace_URL, JSON.stringify(login), { headers: this.headers });
      }

    }


  // api call for reset password
  resetpassword(resetpassword) {

    return this.httpClient.put<myData>(this.rest.RESETPWD_URL, JSON.stringify(resetpassword), { headers: this.headers });

  }

  // api call for forgot password
  forgotPassword(email) {

    return this.httpClient.post<myData>(this.rest.forgot_URL, JSON.stringify(email), { headers: this.headers });

  }

  // api call for rest password
  resetPasswordMail(reset) {
    return this.httpClient.post<myData>(this.rest.reset_Password_Mail, JSON.stringify(reset), { headers: this.headers })
  }

  isAuthenticated(): boolean {
    return true;
  }

  //LogOut method
  logout() {
    // post these details to API server return user info if correct
    return this.httpClient.get<myData>(this.rest.logOut_URL, { headers: this.headers })
  }



  retreivetownshipdata() {
    return this.httpClient.get<myData>(this.rest.RETRIEVE_TOWNSHIPMASTERFORLOGIN_URL, { headers: this.headers })
  }
 async retrieveDNSName(tableName){
    this.spinner.start();


    let response = await this.httpClient.get<myData>(this.rest.RETRIEVEDROPDOWN_URL + "/" +tableName, { headers: this.headers }).toPromise();

    console.log(response)


  }

  // save(login) {

  //   console.log(login);

  //   this.spinner.start()

  //   // call to login service method
  //   this.login(login).subscribe( veevares => {
  //     this.veevares = veevares;
  //     console.log(this.veevares);
  //     console.log(this.veevares.responseStatus)
  //      if(this.veevares.responseStatus == "SUCCESS"){
  //         this.localStorageSettings.saveSettings("SessionId", this.veevares.sessionId);

  //         this.saveLogin(login);

  //      }
  //            else {

  //        this.snackbar.open("Username or Password incorrect", "OK", { duration: 5000 });

  //      }
  //   });
  //   this.spinner.stop()


  // }

  //call to login service method
  veevalogin(login,env) {

    console.log(login);

    this.spinner.start()

    // call to login service method

    this.loginUser(login,env).subscribe(async res => {

      console.log(res);

      if (res.error === true) {

        console.log("res.error : ");

        console.log(res.error);

        //checking if the user is logged in or not

        if (res.data == "100") {

          console.log("res.data : ");

          console.log(res.data);

          if (confirm(res.statusMessage) == true) {

            // api call to replace user
            this.loginReplace(login,env).subscribe(async res => {

              // checking if the respose is true or not
              if (res.error === true) {

                console.log(res);

                this.spinner.stop();

                this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });

              }

              else {

                console.log(res);

                this.users = res.users;

                // token and username are going to store in local storage
                this.localStorageSettings.saveSettings("AuthToken", res.token);

                this.localStorageSettings.saveSettings("AuthUsername", this.users.username);
                this.localStorageSettings.saveSettings("EncrptPassword", login.password);
                this.localStorageSettings.saveSettings("AuthUser", this.users.firstname + " " + this.users.lastname);

                this.localStorageSettings.saveSettings("UserId", this.users.userid);
                this.localStorageSettings.saveSettings("defaultVaultId", res.defaultVaultId);
                this.localStorageSettings.saveSettings("vaultIds", res.vaultIds);
                this.localStorageSettings.saveSettings("dnsName", res.dnsName);
                this.localStorageSettings.saveSettings("vaultDnsname", res.vaultDnsname);
                this.localStorageSettings.saveSettings("msalUser",false);

                let userData: any = await this.commonService.retrieveSingleRecordService("users", "username", this.users.username);

                console.log(userData[0]);

                console.log(userData[0].colorschemeid);

                let colorSchemeData: any = await this.commonService.retrieveSingleRecordService("colorscheme", "colorschemeid", userData[0].colorschemeid);

                console.log(colorSchemeData);

                console.log(this.users);

                this.localStorageSettings.saveSettings("ColorA", colorSchemeData[0].colora);

                this.localStorageSettings.saveSettings("ColorB", colorSchemeData[0].colorb);

                this.localStorageSettings.saveSettings("ColorC", colorSchemeData[0].colorc);

                this.localStorageSettings.saveSettings("ColorD", colorSchemeData[0].colord);

                //if successfull it will navigate to user module
                this.router.navigateByUrl("user")

              }
            })
          } else {
            console.log(res)
            this.spinner.stop()
          }
        }
        else {

          console.log(res)

          this.spinner.stop()

          this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });

        }

      }

      else {
        console.log("login Result")
        console.log(res);

        // token and username are going to store in local storage
        this.users = res.users;

        console.log(this.users);

        this.localStorageSettings.saveSettings("AuthToken", res.token);

        this.localStorageSettings.saveSettings("AuthUsername", this.users.username);
        this.localStorageSettings.saveSettings("EncrptPassword", login.password);
        this.localStorageSettings.saveSettings("AuthUser", this.users.firstname + " " + this.users.lastname);

        this.localStorageSettings.saveSettings("UserId", this.users.userid);

        this.localStorageSettings.saveSettings("defaultVaultId", res.defaultVaultId);
        this.localStorageSettings.saveSettings("vaultIds", res.vaultIds);
        this.localStorageSettings.saveSettings("dnsName", res.dnsName);
        this.localStorageSettings.saveSettings("vaultDnsname", res.vaultDnsname);
        this.localStorageSettings.saveSettings("msalUser",false);
        let userData: any = await this.commonService.retrieveSingleRecordService("users", "username", this.users.username);

        console.log(userData[0]);

        console.log(userData[0].colorschemeid);

        let colorSchemeData: any = await this.commonService.retrieveSingleRecordService("colorscheme", "colorschemeid", userData[0].colorschemeid);

        console.log(colorSchemeData);

        console.log(this.users);

        this.localStorageSettings.saveSettings("ColorA", colorSchemeData[0].colora);

        this.localStorageSettings.saveSettings("ColorB", colorSchemeData[0].colorb);

        this.localStorageSettings.saveSettings("ColorC", colorSchemeData[0].colorc);

        this.localStorageSettings.saveSettings("ColorD", colorSchemeData[0].colord);

        //if successfull it will navigate to user module
        this.router.navigateByUrl("user");

      }
    })

  }

  resetpasswordmethod(reset) {
    this.resetPasswordMail(reset).subscribe(res => {
      console.log(reset);
      console.log(res);
      this.spinner.stop();
      this.snackbar.open(res.message, "OK", {
        duration: 5000,
      });
    })
  }

  //logout method
  sendPassword(userEmail) {
    //api call for logout
    this.spinner.start()
    this.forgotPassword(userEmail).subscribe(data => {
      if (data.error == true) {
        this.spinner.stop()
        this.snackbar.open(data.message, "OK", {
          duration: 5000,
        });
      } else {
        this.spinner.stop()
        this.snackbar.open(data.message, "OK", {
          duration: 5000,
        });
        this.router.navigate([''])
      }
    })
  }

  //logout method
  logoutmethod() {
    //api call for logout
    this.spinner.start()
    this.logout().subscribe(data => {

      console.log(data);

      if (data.error == true) {
        this.spinner.stop()
        this.snackbar.open(data.statusMessage, "OK", {
          duration: 5000,
        });
      } else {
        /*@author:-Chaitanya
        Date:-27-04-2022
        */
                // if success it will clear all the data in the local storage
                localStorage.clear();
                //Instead of navigate using href to reload the login page once user is logged out.
                // finally redirecting to login page
                location.href = '';
        this.spinner.stop();
        this.snackbar.open(data.statusMessage, "OK", {
          duration: 5000,
        });
      }
    })
  }
  msalLogoutmethod() {
    //api call for logout
    this.spinner.start()
    this.logout().subscribe(data => {

      console.log(data);

      if (data.error == true) {
        this.spinner.stop()
        this.snackbar.open(data.statusMessage, "OK", {
          duration: 5000,
        });
      } else {
        /*@author:-Chaitanya
        Date:-27-04-2022
        */
                // if success it will clear all the data in the local storage
                localStorage.clear();
                this.msalService.logoutRedirect({postLogoutRedirectUri:location.origin})
                // this.msalService.logoutRedirect({postLogoutRedirectUri:'http://localhost:4200'})
                //Instead of navigate using href to reload the login page once user is logged out.
                // finally redirecting to login page
                // location.href = '';
        this.spinner.stop();
        this.snackbar.open(data.statusMessage, "OK", {
          duration: 5000,
        });
      }
    })
  }
  insertCustomerSupport(customerSupport) {
    return this.httpClient.post<myData>(
      this.rest.CUSTOMERSUPPORT_URL,
      JSON.stringify(customerSupport),
      { headers: this.headers }
    );
  }

  async retrieveVaultConfigDetails(dnsId){
    return this.httpClient.get<response>(this.rest.RETRIEVE_VAULTCONFIG_DETAILS+"/"+dnsId, { headers: this.headers })
  }

  updateAutorenewSession(autorenewvalue){
    return this.httpClient.post<myData>(this.rest.UPDATE_AUTORENEW_SESSION+"/"+ autorenewvalue, { headers: this.headers });
  }

  deleteSessionRenewSchedular(dnsId,autosessionRenewValue){
    return this.httpClient.get<response>(this.rest.STOP_SESSION_AUTORENEW+"/"+dnsId+"/"+ autosessionRenewValue,{ headers: this.headers })
  }

  addSessionRenewSchedular(dnsId,autosessionRenewValue){
    return this.httpClient.get<response>(this.rest.START_SESSION_AUTORENEW_SCHEDULAR+"/"+dnsId+"/"+autosessionRenewValue,{ headers: this.headers })
  }

  microsoftLogin(username) {

    console.log(username);

    this.spinner.start()

    // call to login service method
    this.msalLogin(username,"Microsoft").subscribe(async res => {

      console.log(res);

      if (res.error === true) {

        console.log("res.error : ");
 
        console.log(res.error);

        console.log(res)
        localStorage.clear();
        // this.msalService.logoutRedirect({postLogoutRedirectUri:'http://localhost:4200'})
        this.spinner.stop()

        this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
        this.msalService.logoutRedirect({postLogoutRedirectUri:location.origin})
      }else {
        console.log("login Result")
        console.log(res);

        // token and username are going to store in local storage
        this.users = res.users;

        console.log(this.users);

        this.localStorageSettings.saveSettings("AuthToken", res.token);

        this.localStorageSettings.saveSettings("AuthUsername", this.users.username);

        this.localStorageSettings.saveSettings("AuthUser", this.users.firstname + " " + this.users.lastname);

        this.localStorageSettings.saveSettings("UserId", this.users.userid);

        this.localStorageSettings.saveSettings("defaultVaultId", res.defaultVaultId);
        this.localStorageSettings.saveSettings("vaultIds", res.vaultIds);
        this.localStorageSettings.saveSettings("dnsName", res.dnsName);
        this.localStorageSettings.saveSettings("vaultDnsname", res.vaultDnsname);
        this.localStorageSettings.saveSettings("msalUser",true);
        
        let userData: any = await this.commonService.retrieveSingleRecordService("users", "username", this.users.username);

        console.log(userData[0]);

        console.log(userData[0].colorschemeid);

        let colorSchemeData: any = await this.commonService.retrieveSingleRecordService("colorscheme", "colorschemeid", userData[0].colorschemeid);

        console.log(colorSchemeData);

        console.log(this.users);

        this.localStorageSettings.saveSettings("ColorA", colorSchemeData[0].colora);

        this.localStorageSettings.saveSettings("ColorB", colorSchemeData[0].colorb);

        this.localStorageSettings.saveSettings("ColorC", colorSchemeData[0].colorc);

        this.localStorageSettings.saveSettings("ColorD", colorSchemeData[0].colord);

        //if successfull it will navigate to user module
        this.router.navigateByUrl("user");

      }
    })

  }
  generateTokenSSOLogin(username) {

    console.log(username);

    this.spinner.start()

    // call to login service method
    this.msalLogin(username,"SSO").subscribe(async res => {

      console.log(res);

      if (res.error === true) {

        console.log("res.error : ");
 
        console.log(res.error);

        console.log(res)
        localStorage.clear();
        // this.msalService.logoutRedirect({postLogoutRedirectUri:'http://localhost:4200'})
        this.spinner.stop()

        this.snackbar.open(res.statusMessage, "OK", { duration: 5000 });
        // this.msalService.logoutRedirect({postLogoutRedirectUri:location.origin})
      }else {
        console.log("login Result")
        console.log(res);

        // token and username are going to store in local storage
        this.users = res.users;

        console.log(this.users);

        this.localStorageSettings.saveSettings("AuthToken", res.token);

        this.localStorageSettings.saveSettings("AuthUsername", this.users.username);

        this.localStorageSettings.saveSettings("AuthUser", this.users.firstname + " " + this.users.lastname);

        this.localStorageSettings.saveSettings("UserId", this.users.userid);

        this.localStorageSettings.saveSettings("defaultVaultId", res.defaultVaultId);
        this.localStorageSettings.saveSettings("vaultIds", res.vaultIds);
        this.localStorageSettings.saveSettings("dnsName", res.dnsName);
        this.localStorageSettings.saveSettings("vaultDnsname", res.vaultDnsname);
        this.localStorageSettings.saveSettings("msalUser",true);
        
        let userData: any = await this.commonService.retrieveSingleRecordService("users", "username", this.users.username);

        console.log(userData[0]);

        console.log(userData[0].colorschemeid);

        let colorSchemeData: any = await this.commonService.retrieveSingleRecordService("colorscheme", "colorschemeid", userData[0].colorschemeid);

        console.log(colorSchemeData);

        console.log(this.users);

        this.localStorageSettings.saveSettings("ColorA", colorSchemeData[0].colora);

        this.localStorageSettings.saveSettings("ColorB", colorSchemeData[0].colorb);

        this.localStorageSettings.saveSettings("ColorC", colorSchemeData[0].colorc);

        this.localStorageSettings.saveSettings("ColorD", colorSchemeData[0].colord);

        //if successfull it will navigate to user module
        this.router.navigateByUrl("user");

      }
    })

  }
 async retrieveSystemName(id){
    return this.httpClient.get<response>(this.rest.RETRIEVE_SYSTEMNAME+"/"+ id,{ headers: this.headers })
  }

   async getsystemName(lookupid){
    return this.httpClient.get<response>(this.rest.RETRIEVE_SYSTEMNAME+"/"+ lookupid,{ headers: this.headers })
  }
  getSystemNamebyid(lookupid){
    return this.httpClient.get<response>(this.rest.RETRIEVE_SYSTEMNAME+"/"+ lookupid,{ headers: this.headers })
  }

  retrieveLookupvalue(lookupvalueid){
    return this.httpClient.get<response>(this.rest.RETRIEVE_LOOKUPVALUE+"/"+lookupvalueid,{ headers: this.headers })
  }

  async getAllVaultVersion(dnsName){
    return this.httpClient.get<response>(this.rest.RETRIEVE_VAULT_VERSIONS+"/"+dnsName,{ headers: this.headers })
  }

  updateVaultVersion(vaultversion,dnsId){
    return this.httpClient.get<response>(this.rest.UPDATE_VAULT_VERSION+"/"+vaultversion+"/"+dnsId);
  }
  sampleTest() {
    // post these details to API server return user info if correct
    return this.httpClient.get<myData>(this.rest.SAMPLETEST, { headers: this.headers })
  }
  decryptUsername(data) {
    // post these details to API server return user info if correct
    return this.httpClient.get<response>(this.rest.DECRYPT_USERNAME+"/"+data, { headers: this.headers })
  }

  storeCatalogCsvRecord(dnsId){
    return this.httpClient.get<response>(this.rest.STORE_CATALOG+"/"+dnsId,{ headers: this.headers })
  }

  retrieveARISjCatalogCsv(dnsId){
    return this.httpClient.get<response>(this.rest.RETRIEVE_ARISj_CATALOG_CSV+"/"+dnsId,{ headers: this.headers })
  }
  retrieveARISgCatalogCsv(dnsId){
    return this.httpClient.get<response>(this.rest.RETRIEVE_ARISg_CATALOG_CSV+"/"+dnsId,{ headers: this.headers })
  }

  getMatchingCase(casenumber,sourceDB,dnsId){
    return this.httpClient.get<response>(this.rest.GET_MATCHING_CASE+"/"+casenumber+"/"+sourceDB+"/"+dnsId,{ headers: this.headers })
  }

  getAuditTrailMatchingCase(casenumber,sourceDB,dnsId){
    return this.httpClient.get<response>(this.rest.GET_AUDIT_MATCHING_CASE+"/"+casenumber+"/"+sourceDB+"/"+dnsId,{ headers: this.headers })
  }

  storeAuditCatalog(dnsId){
    return this.httpClient.get<response>(this.rest.STORE_AUDIT_CATALOG+"/"+dnsId,{ headers: this.headers })
  }
}

