import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageSettingsService {

  constructor() { }

  public saveSettings(key: string, value: any) {

    window.localStorage.removeItem(key);

    window.localStorage.setItem(key, value);

  }

  public getSettings(key: string) {

    return localStorage.getItem(key);

  }

}