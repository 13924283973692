import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-generatedSummaryReport',
  template: `
  <a (click)="reportdownload($event)" style="color: cornflowerblue;text-decoration: underline;">{{templateFileName}}</a>`
})
export class Reportdownload implements ICellRendererAngularComp {

  params;
  label: string;
  templateFileName

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params);
    this.templateFileName=this.params.data.templateFileName;
    console.log(this.templateFileName);
  }

  refresh(params?: any): boolean {
    return true;
  }

  reportdownload($event) {
    console.log("hello")
    if (this.params.reportdownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.reportdownload(params);

    }
  }
}
