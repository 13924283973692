import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-resumeLoadButton',
  template: `
  <button title="Resume Load" (click)="resumeLoadButton($event);"  class="btn btn-outline btn-success btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" [disabled]="params.node.data.isLoadActionDelete || params.node.data.isStateDeleted || isdisable">
  <i class="fas fa-play-circle"></i>
  </button>`
})
export class ResumeLoadButton implements ICellRendererAngularComp {

  params;
  label: string;
  isdisable:boolean=false;

  agInit(params): void {
    this.params = params;
    console.log(params.colDef.headerName);
    if(params.colDef.headerName== 'Action'){
      this.isdisable=true;
      if(params.data.migrationType=="Data Migration"){
        this.isdisable=false;
      }
    }
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  resumeLoadButton($event) {
    console.log("hello")
    if (this.params.resumeLoadButton instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.resumeLoadButton(params);

    }
  }
}
