import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-generateReport',
  template: `
  <button (click)="generateReport($event);" [disabled]="isdisable" class="btn btn-outline btn-success btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-play-circle"></i>
  </button>`
})
export class GenerateButton implements ICellRendererAngularComp {

  params;
  label: string;
  isdisable:boolean=false;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
    if(params.colDef.headerName== 'Generate Split Report'||params.colDef.headerName== 'Generate Summary Report'||params.colDef.headerName== 'Validate Mapping File'){
      this.isdisable=true;
      if(params.data.migrationType=="Data Migration"){
        this.isdisable=false;
      }

    }
    /**
     * Modified By : Nitin Kumar
     * enable the Generate Summary Report button and  Generate Split Report for Data Migration & Data Virtualization
     * Date 10/07/2024
     */

    if(params.colDef.headerName== 'Generate Summary Report' || params.colDef.headerName== 'Generate Split Report'){
      if(params.data.migrationType=="Data Migration" || params.data.migrationType=="Data Virtualization"){
        this.isdisable=false;
      }
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  generateReport($event) {
    console.log("hello")
    if (this.params.generateReport instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.generateReport(params);

    }
  }
}
