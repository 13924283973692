import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { type } from 'os';
import { Downloadbutton } from 'src/app/shared/common/downloadbutton';
import { ViewButton } from 'src/app/shared/common/view-button';
import { AsignUserRoleService } from 'src/app/shared/services/asign-user-role.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-audittrail-html-report',
  templateUrl: './audittrail-html-report.component.html',
  styleUrls: ['./audittrail-html-report.component.scss']
})
export class AudittrailHtmlReportComponent implements OnInit {

  gridAPI;
  currentPage;
  totalPages;
  pageSize;
  value;
  backgroundColorScreen = '';
  backgroundColorButton = '';
  systemName;
  caseNumberValue;
  defaultColDef;
  columnDefs;
  lastPage;
  nextPage;
  nextPagePlusOne;
  paginationPageSize;
  paginationNumberFormatter;
  frameworkComponents: any;
  rowClassRules;
  gridColumnAPI;
  dbNameValue;
  dbSelected=false;
  catalogRecords: Map<string, string>;
  filteredSuggestions:string[]=[];
  caseNumbers:string[];
  showSuggestions = false;
  mapData: Map<string, string>;
  isInputDisabled=false;
  constructor( private dialog: MatDialog, private commonService: CommonService, private localStorage: LocalStorageSettingsService,private asignUserRoleService:AsignUserRoleService,
    private snackBar:MatSnackBar,private spinner:NgxUiLoaderService,
    private loginService: LoginService,
    private localStorageSettings: LocalStorageSettingsService,
    private elementRef: ElementRef) { 
    this.frameworkComponents = {
      download: Downloadbutton,
      view:ViewButton
    };
  }

  ngOnInit(): void {
   // this.retrieveCatalogCsv();
    this.backgroundColorScreen = this.localStorage.getSettings("ColorD");

    this.backgroundColorButton = this.localStorage.getSettings("ColorA");
    this.defaultColDef = {

      sortable: true,

      resizable: true,

      filter: true

    };
    this.columnDefs = [
      {
        headerName: 'Case Number',
        headerTooltip: 'Case Number',
        tooltipValueGetter: (params) =>
          'Case Number : ' + params.value,
        field: 'caseNumber',
        filter: 'agTextColumnFilter',
        flex: 1, // Set flex to 1 to make the column fill available width
      },
      {
        headerName: 'View Report',
        filter: false,
        cellRenderer: 'view',
        cellRendererParams: {
          view: this.viewReport.bind(this), 
        },
        flex: 1, // Set flex to 1 to make the column fill available width
      },
      {
        headerName: 'Download',
        filter: false,
        cellRenderer: 'download',
        cellRendererParams: {
          download: this.downloadCase.bind(this), 
        },
        flex: 1, // Set flex to 1 to make the column fill available width
      },
    ];
    
  }

  select(value){
    this.dbSelected=true;
this.dbNameValue= value;
console.log(this.dbNameValue);
 //this.retrieveCatalogCsv();
 this.gridAPI.setRowData(null);

      }

      onGridReady(params){
        this.gridAPI = params.api;
        console.log(this.gridAPI)
        this.gridColumnAPI = params.columnApi;
        this.gridAPI.setRowData(null);
      }

    // below function for going to first page
    onBtFirst() {

      this.gridAPI.paginationGoToFirstPage();
  
    }
  
    // below function for going to last page
    onBtLast() {
  
      this.gridAPI.paginationGoToLastPage();
  
    }
      //this function is for going to the given page number
  onSearchChange(searchValue: number) {

    this.gridAPI.paginationGoToPage(searchValue - 1);

  }

  // below function is for changing the no of rows per page
  onPageSizeChanged(newPageSize) {

    var value = this.pageSize;

    this.gridAPI.paginationSetPageSize(Number(value));

  }

  // below function is for going to next page
  async onBtNext() {
    this.gridAPI.paginationGoToNextPage();
  }

  // below function for going to previous page
  async onBtPrevious() {

    this.gridAPI.paginationGoToPreviousPage();

  }
  //excel export function
  onBtExport() {

    this.gridAPI.exportDataAsCsv();

  }
  onPaginationChangedReturn() {
    if (this.gridAPI) {
      this.lastPage = this.gridAPI.paginationIsLastPageFound();
      this.currentPage = this.gridAPI.paginationGetCurrentPage() + 1;
      this.nextPage = this.gridAPI.paginationGetCurrentPage() + 1 + 1;
      this.nextPagePlusOne =
        this.gridAPI.paginationGetCurrentPage() + 1 + 1 + 1;
      this.totalPages = this.gridAPI.paginationGetTotalPages();
    }
  }

  downloadCase(e){
    
    console.log(e);
    let s3folder = e.rowData.partitionFolderName;
    this.spinner.start();
    this.commonService.auditTrailHTML(e.rowData.partitionFolderName,e.rowData.caseNumber,this.localStorage.getSettings("dnsName"),this.dbNameValue).subscribe(res=>{
      console.log(res)
      if(res=='undefined'|| res==''){
        this.snackBar.open("File does not exists.", "OK", { duration: 5000 });
        this.spinner.stop();
        return;
      }
      this.spinner.stop();
      const blob = new Blob([res], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download =e.rowData.caseNumber +'.html';
      a.click();
      
      URL.revokeObjectURL(url);
    })
    
  }

  viewReport(e){
    console.log(e);
    let s3folder = e.rowData.partitionFolderName;
    let radioValue= this.dbNameValue;
    this.spinner.start();
    this.commonService.auditTrailHTML(e.rowData.partitionFolderName,e.rowData.caseNumber,this.localStorage.getSettings("dnsName"),this.dbNameValue).subscribe(res=>{
      console.log(res)
      if(res=='undefined'|| res==''){
        this.snackBar.open("File does not exists.", "OK", { duration: 5000 });
        this.spinner.stop();
        return;
      }
      
      this.spinner.stop();
 const newWindow = window.open('', '_blank');
newWindow.document.open();
newWindow.document.write(res);
newWindow.document.close();
    })
  }

  onSearch(caseNumber,sourceDB){
    console.log(this.dbNameValue)
   
if(!this.dbSelected){
  this.snackBar.open("Please select Source Database.", "OK", { duration: 5000 });
  return;
}
//caseNumber.length<7||

    if( caseNumber=='undefined'|| caseNumber==''){
      this.snackBar.open("Please enter a case number.", "OK", { duration: 5000 });
      return;
    }
    this.spinner.start();
    this.showSuggestions=false;
    // const filteredArray = this.caseNumbers.filter(item => item.startsWith(caseNumber));
    // console.log(filteredArray)
    let rowData=[];
      this.loginService.getAuditTrailMatchingCase(caseNumber,sourceDB,this.localStorageSettings.getSettings("dnsName")).subscribe(res=>{
        console.log(res);
        const mapData = new Map(Object.entries(res));
        this.catalogRecords = mapData;
        console.log(this.catalogRecords)
       this.caseNumbers  = Array.from(mapData.keys());
       console.log(this.caseNumbers)
      
    for(let i=0;i<this.caseNumbers .length;i++){
      let obj = {
        "caseNumber":this.catalogRecords.get(this.caseNumbers [i]),
        "partitionFolderName":this.catalogRecords.get(this.caseNumbers [i])
      }
      rowData.push(obj);
    }
    console.log(rowData)
    this.gridAPI.setRowData(rowData);
       this.spinner.stop();
      })
   }

   
   retrieveCatalogCsv(){
    this.spinner.start();
    console.log(this.dbNameValue)
    if(this.dbNameValue==='ARISj'){
      this.loginService.retrieveARISjCatalogCsv(this.localStorageSettings.getSettings("dnsName")).subscribe(res=>{
        console.log(res);
        const mapData = new Map(Object.entries(res));
        this.catalogRecords = mapData;
        console.log(this.catalogRecords)
       this.caseNumbers  = Array.from(mapData.keys());
       this.spinner.stop();
      })
    }
    if(this.dbNameValue==='ARISg'){
      this.loginService.retrieveARISgCatalogCsv(this.localStorageSettings.getSettings("dnsName")).subscribe(res=>{
        console.log(res);
        const mapData = new Map(Object.entries(res));
        this.catalogRecords = mapData;
        console.log(this.catalogRecords)
       this.caseNumbers  = Array.from(mapData.keys());
       this.spinner.stop();
      })
    }
   
    
   }
   
   

  

}
