import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-filedownload',
  template: `
  <button title="Extract Download" (click)="extractDownload($event);" [disabled]="params.node.data.isStateDeleted" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>
  <button title="Transform Download" (click)="transformDownload($event);" [disabled]="params.node.data.isStateDeleted" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>
  <button title="Load Download" (click)="loadDownload($event);" [disabled]="params.node.data.isStateDeleted" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>
  <button title="Vault Extracted Files" (click)="vaultExtractedDownload($event);" [disabled]="params.node.data.isStateDeleted" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
  <i class="fas fa-download"></i>
  </button>`
})
export class Migrationtracelogs implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  extractDownload($event) {
    console.log("hello")
    if (this.params.extractDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        activity:"Extraction"
        // ...something
      }
      console.log(params)
      this.params.extractDownload(params);

    }
  }
  transformDownload($event) {
    console.log("hello")
    if (this.params.transformDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        activity:"Transform"
        // ...something
      }
      console.log(params)
      this.params.transformDownload(params);

    }
  }
  loadDownload($event) {
    console.log("hello")
    if (this.params.loadDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        activity:"Load"
        // ...something
      }
      console.log(params)
      this.params.loadDownload(params);

    }
  }
  // Function for downloading vault extracted files written by kalpesh
  vaultExtractedDownload($event){
    console.log("Vault Extracted")
    if (this.params.vaultExtractedDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        activity:"Validation"
        // ...something
      }
      console.log(params)
      this.params.vaultExtractedDownload(params);

    }
  }
}
