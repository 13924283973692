import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-filedownload',
  template: `
  <button title="Qualitative Report" (click)="qlDownload($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" [disabled]="params.node.data.isStateDeleted">
  <i class="fas fa-download"></i>
  </button>
  <button title="Quantitative Report" (click)="qtDownload($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" [disabled]="params.node.data.isStateDeleted">
  <i class="fas fa-download"></i>
  </button>
  <button title="EMR Logs" (click)="emrLogsDownload($event);" class="btn btn-outline btn-primary btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem" [disabled]="params.node.data.isStateDeleted">
  <i class="fas fa-download"></i>
  </button>`
})
export class ValidationReportDownload implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  qlDownload($event) {
    console.log("hello")
    if (this.params.qlDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.qlDownload(params);

    }
  }

  qtDownload($event) {
    console.log("hello")
    if (this.params.qtDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.qtDownload(params);

    }
  }

  emrLogsDownload($event) {
    console.log("hello")
    if (this.params.emrLogsDownload instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      console.log(params)
      this.params.emrLogsDownload(params);

    }
  }
}
