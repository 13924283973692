import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-action',
  template: `
  <div *ngIf="publish">
  <button title="Publish" (click)="publishButton($event);" class="btn btn-outline btn-success btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
    <i class="fas fa-upload"></i>
  </button>
  </div>
  <div *ngIf="unpublish">
  <button title="UnPublish" (click)="unPublishButton($event);" class="btn btn-outline btn-danger btn-rounded btn-sm waves-effect waves-light" style="padding: .3rem .8rem;margin-top:0rem">
    <i class="fas fa-remove"></i>
  </button>
  </div>`
  
})

export class publishButton implements ICellRendererAngularComp {
  params;
  label: string;
  publish:boolean = false;
  unpublish:boolean = false;

  agInit(params): void {
    console.log(params.data)
    if(params.data.status=="Yes"){
      this.publish= false;
      this.unpublish = true;
    }else if(params.data.status=="No"){
      this.unpublish= false;
      this.publish =true;
    }
    this.params = params;
    this.label = this.params.label || null;
    console.log(this.params+"fdgsdgsdgjkl;j")
    console.log(this.label+"fdgsdgsdgjkl;j")
  }

  refresh(params?: any): boolean {
    return true;
  }

  publishButton($event) {
    console.log("sfgggsdgsg")
    console.log($event)
    if (this.params.publishButton instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        
        // ...something
      }
      console.log(params)
      this.params.publishButton(params);

    }
  }

  unPublishButton($event) {
    console.log("sfgggsdgsg")
    console.log($event)
    if (this.params.publishButton instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        
        // ...something
      }
      console.log(params)
      this.params.unPublishButton(params);

    }
  }
}