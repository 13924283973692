import { Injectable } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { CommonService } from "src/app/shared/services/common.service";
import * as moment from "moment";
import { FormFieldsTrimAndClean } from "src/app/shared/common/trim-clean-Data";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImageviewPopupComponent } from 'src/app/user/imageview-popup/imageview-popup.component';
import { ScreenCreatorService } from "src/app/shared/services/screen-creator.service";
import { validationmethod } from "src/app/shared/common/validationmethod";
import { LocalStorageSettingsService } from 'src/app/shared/services/local-storage-settings.service';

@Injectable({
    providedIn: 'root'
})

export class CommonTs {

    constructor(
        private service: CommonService,
        private trimCleanData: FormFieldsTrimAndClean,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private screenCreatorService: ScreenCreatorService,
        private validation: validationmethod,
        private localStorageSettings: LocalStorageSettingsService
    ) { }

    btnname = "Save";

    async ngModelObjectCreation(tableName) {

        let dynamicData = [];

        let ngModelObject = [];

        let colSpanValue = 0;

        let ngModelObjectDivisionLocal = [];

        let ngModelObjectDivision = [];

        let bootstrapColumn = 0;

        let tableID = "";

        let retrieveTableData: any = [];

        let radio: any = []

        retrieveTableData = await this.screenCreatorService.retrieveScreenByTableNameService(tableName);

        console.log(retrieveTableData.tableDetails);
        console.log(retrieveTableData.tableDetails);

        dynamicData = JSON.parse(retrieveTableData.tableDetails).tableData;

        console.log(dynamicData);

        colSpanValue = JSON.parse(retrieveTableData.tableDetails).colSpan;

        console.log(colSpanValue);

        tableID = JSON.parse(retrieveTableData.tableDetails).tableID;

        console.log(tableID);

        console.log(dynamicData);

        dynamicData.forEach(dynamicDataObject => {

            dynamicDataObject["imageShow"] = false;

            dynamicDataObject["imageShow1"] = false;

            dynamicDataObject["fileShow"] = false;

            dynamicDataObject["imageNameDisplay"] = null;

            dynamicDataObject["startDateValue"] = moment(null);

            dynamicDataObject["endDateValue"] = moment(null);

            dynamicDataObject["binaryFormat"] = false;

            dynamicDataObject["downloadIconShow"] = false;

            dynamicDataObject["startDateTimeValue"] = moment(null);

            dynamicDataObject["endDateTimeValue"] = moment(null);

        });

        console.log(dynamicData);
        console.log(dynamicData)

        //creating ngModelObject
        let i = 0;

        dynamicData.forEach(e => {

            let jsonObject = {};

            jsonObject["keyName"] = e.columnName;

            jsonObject["keyValue"] = "";

            jsonObject["displayFormColumnName"] = e.displayFormColumnName;

            jsonObject["displayAgGridColumnName"] = e.displayAgGridColumnName;

            jsonObject["maximumLength"] = e.maximumLength;

            jsonObject["formControlValidationName"] = e.formControlValidationName;

            jsonObject["validationMessages"] = [];

            if (e.columnType == "valueText" || e.columnType == "valueFileName" || e.columnType == "valuePassword" || e.columnType == "valueEmail" || e.columnType == "valueTextArea") {

                jsonObject["validationMessages"] = [{ type: 'maxlength', message: e.displayFormColumnName + " is exceed maximum length " + e.maximumLength + "." }];

            }

            if (e.columnType == "valueColor") {

                jsonObject["validationMessages"] = [{ type: 'maxlength', message: e.displayFormColumnName + " is exceed maximum length " + e.maximumLength + "." }];

            }

            if (e.columnType == "valueText" || e.columnType == "valueTextArea") {

                if (e.isRequired == true) {

                    console.log(e.isRequired);

                    jsonObject["validationMessages"].push({ type: 'whitespace', message: e.displayFormColumnName + " should not contain any white spaces." });

                }

            }

            if (e.columnType == "valueColor") {

                if (e.isRequired == true) {

                    console.log(e.isRequired);

                    jsonObject["validationMessages"].push({ type: 'whitespace', message: e.displayFormColumnName + " should not contain any white spaces." });

                }

            }

            if (e.columnType == "valueInteger" || e.columnType == "valueNumbers") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.displayFormColumnName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.displayFormColumnName + " allows only integers." });

            }

            if (e.columnType == "valueDecimal") {

                jsonObject["validationMessages"].push({ type: 'max', message: e.displayFormColumnName + " is exceed maximum value." });

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.displayFormColumnName + " allows only numbers." });

            }

            if (e.columnType == "valuePassword") {

                jsonObject["validationMessages"].push({ type: 'pattern', message: e.displayFormColumnName + " must contain at least one uppercase, one lowercase, one special character and one number." });

            }

            if (e.columnType == "valueEmail") {

                jsonObject["validationMessages"].push({ type: 'email', message: "Enter valid " + e.displayFormColumnName + "." });

            }

            jsonObject["validationMessages"].push({ type: 'required', message: e.displayFormColumnName + " is required." });

            jsonObject["formColumnShow"] = e.formColumnShow;

            jsonObject["columnType"] = e.columnType;

            jsonObject["dropDownIndex"] = e.dropDownIndex;

            jsonObject["dropDownChildFieldName"] = e.dropDownChildFieldName;

            jsonObject["columnNameDropDown"] = e.columnNameDropDown;

            //jsonObject["agGridDecode"] = e.agGridDecode;

            jsonObject["combinationColumn"] = e.combinationColumn;

            jsonObject["imageName"] = e.imageName;

            if (e.columnType == "valueFileName") {

                jsonObject["readOnlyValue"] = true;

                console.log(jsonObject["readOnlyValue"]);

            }

            else {

                jsonObject["readOnlyValue"] = e.readOnlyValue;

            }

            jsonObject["imageShow"] = e.imageShow;

            jsonObject["imageShow1"] = e.imageShow1;

            jsonObject["fileShow"] = e.fileShow;

            jsonObject["imageNameDisplay"] = e.imageNameDisplay;

            jsonObject["childDropDownKey"] = e.childDropDownKey;

            jsonObject["tableName"] = e.tableName;

            jsonObject["endDateName"] = e.endDateName;

            jsonObject["isRequired"] = e.isRequired;

            jsonObject["startDateName"] = e.startDateName;

            jsonObject["binaryFormat"] = e.binaryFormat;

            jsonObject["startDateTimeName"] = e.startDateTimeName;

            jsonObject["endDateTimeName"] = e.endDateTimeName;

            jsonObject["maximumValue"] = e.maximumValue;

            jsonObject["maximumDecimalValue"] = e.maximumDecimalValue;

            jsonObject["maximumDate"] = e.maximumDate;

            jsonObject["maximumDateTime"] = e.maximumDateTime;

            jsonObject["dropdownLookupType"] = e.dropdownLookupType;

            if (e.radioValues != undefined) {
                radio = e.radioValues
                console.log(radio.split(","))
                jsonObject["valueRadio"] = radio.split(",");
            }

            if (colSpanValue == 1) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-10";

                bootstrapColumn = 1;

            }

            else if (colSpanValue == 2) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

                bootstrapColumn = 2;

            }

            else if (colSpanValue == 3) {

                jsonObject["bootstrapColumnGrid"] = "col-lg-4";

            }

            else {

                jsonObject["bootstrapColumnGrid"] = "col-lg-3";

            }

            if (e.columnType == "valueDate") {

                jsonObject["endDateValue"] = e.maximumDate;

                console.log(jsonObject["endDateValue"]);

            }

            if (e.columnType == "valueDateTime") {

                jsonObject["endDateTimeValue"] = e.maximumDateTime;

                console.log(jsonObject["endDateTimeValue"]);

            }

            console.log(typeof e.imageShow);

            ngModelObject.push(jsonObject);

        });

        //Based on the colspan value storing ngModelObject jsons into ngModelObjectDivision
        let j = 0;

        console.log(ngModelObject);

        for (let i = 0; i < ngModelObject.length; i++) {

            console.log(ngModelObject);

            if (ngModelObject[i].formColumnShow) {

                if (j <= colSpanValue) {

                    ngModelObjectDivisionLocal.push(ngModelObject[i]);

                    console.log(ngModelObjectDivisionLocal);

                    j = j + 1;

                }

                if (j == colSpanValue) {

                    ngModelObjectDivision.push(ngModelObjectDivisionLocal);

                    console.log(ngModelObjectDivision);

                    ngModelObjectDivisionLocal = [];

                    j = 0;

                }

            }

            if ((i + 1) == (ngModelObject.length)) {

                ngModelObjectDivision.push(ngModelObjectDivisionLocal);

                console.log(ngModelObjectDivision);

                ngModelObjectDivisionLocal = [];

                j = 0;

            }

        }

        console.log(dynamicData);

        console.log(ngModelObject);

        console.log(ngModelObjectDivision);

        console.log(bootstrapColumn);

        console.log(tableID);

        return await [dynamicData, ngModelObject, ngModelObjectDivision, bootstrapColumn, tableID, true];

    }

    //Form Validations
    formValidations(dynamicData) {

        let numberPattern = '^[0-9]+$';


        let formGroupJsonObject = {};

        dynamicData.forEach(e => {

            if (e.columnType == "valueDate" || e.columnType == "valueStartDate" || e.columnType == "valueEndDate" || e.columnType == "valueDropDown" || e.columnType == "valueParentDropDown" || e.columnType == "valueSelectListStatic" || e.columnType == "valueDateTime" || e.columnType == "valueStartDateTime" || e.columnType == "valueEndDateTime") {

                console.log(e.columnType);

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("");

                }

            }

            if (e.columnType == "valueText") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength)]);

                }

            }

            if (e.columnType == "valueColor") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength)]);

                }

            }

            if (e.columnType == "valueFileName") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength)]);

                }

            }

            if (e.columnType == "valueRadio") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("");

                }

            }

            if (e.columnType == "valueFilePath") {

                formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("");

            }

            if (e.columnType == "valuePassword") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength)]);

                }

            }

            if (e.columnType == "valueEmail") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required, Validators.email]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.email]);

                }

            }

            if (e.columnType == "valueInteger" || e.columnType == "valueNumbers") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.max(e.maximumValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$'), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.max(e.maximumValue), Validators.pattern('^-?[0-9]\\d*(\\.\\d*)?$')]);

                }

            }

            if (e.columnType == "valueDecimal") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.max(e.maximumDecimalValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/), Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.max(e.maximumDecimalValue), Validators.pattern(/^[0-9]+(?:\.[0-9]+)?$/)]);

                }

            }

            if (e.columnType == "valueCheckbox") {

                console.log(e.columnType);

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.required]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("");

                }

            }

            if (e.columnType == "valueTextArea") {

                if (e.isRequired == true) {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength), Validators.required, this.validation.noWhitespaceValidator]);

                }

                else {

                    formGroupJsonObject[e.formControlValidationName] = new UntypedFormControl("", [Validators.maxLength(e.maximumLength)]);

                }

            }

        });

        let formValid = new UntypedFormGroup(formGroupJsonObject);

        console.log(formValid);

        return formValid;

    }

    async retrieveDropDown(tableName, /*dropDownIdNameFilter, dropDownIdValueFilter,*/ dropDownNames, dropDownValuesDisplay, dropdownLookupType) {

        console.log(tableName);

        console.log(dropDownNames);

        console.log(dropDownValuesDisplay);

        let responseArray: any = [];

        if (tableName != null) {

            console.log(tableName);

            let tenantid = 0;

            if (dropdownLookupType != undefined) {

                responseArray = await this.service.retrieveSingleRecordService("lookupvalue", "lookuptypeid", dropdownLookupType);

                console.log(responseArray);

            }

            else {

                responseArray = await this.service.retrieveDropdownService(tableName, 0, dropDownValuesDisplay, tenantid);

                console.log(responseArray);

            }

        }

        for (let i = 0; i < responseArray.length; i++) {

            //console.log(i);

            //console.log(responseArray[i][dropDownNames]);

            //console.log(responseArray[i][dropDownValuesDisplay]);

            responseArray[i]["keyJson"] = [responseArray[i][dropDownNames]];

            responseArray[i]["dropDownValueDisplay"] = responseArray[i][dropDownValuesDisplay];

        }

        console.log(responseArray);

        return responseArray;

    }

    editIcon(ngModelObject, rowData) {

        console.log(ngModelObject);

        console.log(rowData);

        ngModelObject.forEach(inputObject => {

            inputObject.imageShow = false;

            inputObject.imageShow1 = false;

            inputObject.fileShow = false;

            inputObject.downloadIconShow = false;

        });

        ngModelObject.forEach(inputObject => {

            for (let keyName in rowData) {
                console.log(keyName)
                if (keyName == inputObject.keyName) {

                    console.log(rowData[keyName]);

                    inputObject.keyValue = rowData[keyName];

                }

            }

        });

        ngModelObject.forEach(inputObject => {

            console.log(inputObject);

            if (inputObject.imageName != null && inputObject.keyValue != null) {

                if ((inputObject.keyValue).length > 0) {

                    console.log(inputObject.keyValue);

                    let extension: any;

                    if ((inputObject.keyValue).includes(".")) {

                        extension = (inputObject.keyValue).substring((inputObject.keyValue).lastIndexOf(".") + 1);

                        console.log(extension);

                    }

                    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {



                    }

                    else {

                        inputObject.imageShow = true;

                        inputObject.fileShow = true;

                        inputObject.downloadIconShow = true;

                    }

                }

            }

        });

        for (let i = 0; i < ngModelObject.length; i++) {

            if (ngModelObject[i].imageName != null) {

                console.log(ngModelObject[i].imageName);

                for (let j = 0; j < ngModelObject.length; j++) {

                    if (ngModelObject[i].imageName == ngModelObject[j].keyName) {

                        console.log(ngModelObject[j].keyName);

                        ngModelObject[i].imageNameDisplay = ngModelObject[j].keyValue;

                    }

                }

            }

        }

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.columnType == "valueDate" || ngModelObjectTemporary.columnType == "valueStartDate" || ngModelObjectTemporary.columnType == "valueEndDate") {

                this.changeStartEndDateValue(ngModelObjectTemporary, ngModelObject);

            }

        });

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.columnType == "valueDateTime" || ngModelObjectTemporary.columnType == "valueStartDateTime" || ngModelObjectTemporary.columnType == "valueEndDateTime") {

                this.changeStartEndDateTimeValue(ngModelObjectTemporary, ngModelObject);

            }

        });

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.columnType == "valueCheckbox") {

                if (ngModelObjectTemporary.keyValue == "false") {

                    ngModelObjectTemporary.keyValue = false;

                }

                else {

                    ngModelObjectTemporary.keyValue = true;

                }

            }

        });

        return ngModelObject;

    }

    async deleteIcon(e, tableName, ngModelObject, tableID) {

        console.log(ngModelObject);

        let idValue = "";

        let idName = "";

        idName = tableID

        console.log(idName);

        console.log(e.rowData);

        idValue = e.rowData[idName];

        console.log(idValue);

        await this.service.deleteService(tableName, idName, idValue);

    }

    async retrieve(tableName, listObjectDropDown, /*dropDowns,*/ ngModelObject, recordsStart) {

        let agGridObject: any = [];

        let tenantid = 0;

        //Get the tenantid value from localstorage and storing into tenantid, if tenantid is not present in localStorage it will return null
        let CheckingTenantid = this.localStorageSettings.getSettings("tenantid");

        console.log(CheckingTenantid);

        console.log(CheckingTenantid == null);

        //It will execute when tenantid is not present in localStorage
        if (CheckingTenantid == null) {

            tenantid = 0;

            console.log(tenantid);

            console.log(typeof tenantid);

        }

        else {

            //Get the tenantid value from localstorage
            tenantid = Number(this.localStorageSettings.getSettings("tenantid"));

            console.log(tenantid);

            console.log(typeof tenantid);

        }

        agGridObject = await this.service.retrieveService(tableName, recordsStart, tenantid);

        console.log(agGridObject);

        console.log(listObjectDropDown);

        //console.log(dropDowns);

        //agGridObject is table data
        /*for (let i = 0; i < agGridObject.length; i++) {

            //dropsDowns length is equal to number of dropDowns in form
            for (let j = 0; j < dropDowns.length; j++) {

                console.log(dropDowns[j][0]);

                console.log(dropDowns[j][1]);

                console.log(listObjectDropDown[dropDowns[j][1]]);

                //listObjectDropDowns length is equal to number of dropDowns in form
                for (let l = 0; l < listObjectDropDown[dropDowns[j][1]].length; l++) {

                    console.log(l);

                    //Ids of dropDowns is equal to agGridObject ids, example here ids are codelistid, clcode
                    if (listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][3]] == agGridObject[i][dropDowns[j][0]]) {

                        //Here example codelistid related codelistname is stored in agGridObject, clcode related cldecoded is stored in agGridObject
                        agGridObject[i][dropDowns[j][2]] = listObjectDropDown[dropDowns[j][1]][l][dropDowns[j][4]];

                    }

                }

            }

        }*/

        console.log(agGridObject);

        ngModelObject.forEach(inputObject => {

            if (inputObject.columnType == "valueDateTime" || inputObject.columnType == "valueStartDateTime" || inputObject.columnType == "valueEndDateTime") {

                let keyName = inputObject.keyName;

                agGridObject.forEach(agGridObjectTemp => {

                    if (agGridObjectTemp[keyName] != null) {

                        agGridObjectTemp[keyName] = moment(agGridObjectTemp[keyName]).format('YYYY-MM-DD HH:mm:ss');

                    }

                });

            }

        });

        console.log(agGridObject);

        return agGridObject;

    }

    //Combination of columns as unique in table
    checkingCombinationColumnsUnique(ngModelObject, retrieveObject, isExecute) {

        ngModelObject.forEach(tempObject => {

            console.log(tempObject);

            console.log(tempObject["combinationColumn"]);

            //In combinationColumn it has keyNames, if combiantionColumn is not null
            if (tempObject["combinationColumn"] != null) {

                //keyNames of combination of columns is stored in combinationKeys
                let combinationKeys = tempObject["combinationColumn"].split(",");

                console.log(combinationKeys);

                let combinationValues = [];

                //For getting keyValues of combination of columns
                for (let i = 0; i < combinationKeys.length; i++) {

                    ngModelObject.forEach(tempNgModelObject => {

                        console.log(tempNgModelObject["keyName"]);

                        console.log(combinationKeys[i]);

                        //Comparing keyName of tempNgModelObject is equal to combination key
                        if (tempNgModelObject["keyName"] == combinationKeys[i]) {

                            //The values of combinationKeys is stored in combiantionValues
                            combinationValues.push(tempNgModelObject["keyValue"]);

                            console.log(tempNgModelObject["keyValue"]);

                            console.log(combinationValues);

                        }

                    });

                }

                console.log(ngModelObject);

                let idValue = 0;

                ngModelObject.forEach(ngModelObjectTemporary => {

                    console.log(ngModelObjectTemporary["keyName"]);

                    //Comparing keyName of ngModelObjectTemporary is equalt to id
                    if (ngModelObjectTemporary["keyName"] == "id") {

                        //Storing id value into idValue
                        idValue = ngModelObjectTemporary["keyValue"];

                    }

                });

                //If idValue is not equal to zero, this is for update record
                if (idValue != 0) {

                    retrieveObject.forEach(retrieveObjectTemporary => {

                        //Comparing id of retrieveObjectTemporary is equal to idValue
                        if (idValue == retrieveObjectTemporary["id"]) {

                            console.log(idValue);

                            ngModelObject.forEach(ngModelObjectTemporary => {

                                console.log(retrieveObjectTemporary.hasOwnProperty(ngModelObjectTemporary.keyName))

                                //Checking keyNamge of ngModelObjectTemporary is present in retrieveObjectTemporary
                                if (retrieveObjectTemporary.hasOwnProperty(ngModelObjectTemporary.keyName) == true) {

                                    retrieveObjectTemporary[ngModelObjectTemporary.keyName] = ngModelObjectTemporary["keyValue"];

                                }

                            });

                        }

                    });

                }

                console.log(retrieveObject);

                //Checking combination of columns is unique or not with each record of the retrieveObject
                retrieveObject.forEach(temporaryRetrieveObject => {

                    let checkIsExecute = [];

                    console.log(tempObject["keyValue"]);

                    console.log(temporaryRetrieveObject[tempObject["keyName"]]);

                    console.log(temporaryRetrieveObject[tempObject["combinationColumn"]]);

                    console.log(temporaryRetrieveObject);

                    for (let i = 0; i < combinationKeys.length; i++) {

                        console.log(combinationValues[i]);

                        console.log(combinationKeys[i]);

                        if (combinationValues[i] == temporaryRetrieveObject[combinationKeys[i]]) {

                            checkIsExecute.push(true);

                            console.log(i);

                            console.log(combinationKeys.length);

                            if ((i + 1) == (combinationKeys.length)) {

                                console.log(i + 1);

                                console.log(combinationKeys.length);

                                //It will execute, if unique combination of values is present in record
                                if (checkIsExecute.length == combinationKeys.length) {

                                    console.log(checkIsExecute);

                                    console.log(checkIsExecute.length);

                                    isExecute++;

                                }

                            }

                        }

                    }

                });

            }

        });

        return isExecute;

    }

    async ngModelData(ngModelObject) {

        console.log(ngModelObject);

        //In aggrid for each dropdown each json, those jsons are removing here.
        /*for (let i = 0; i < ngModelObject.length; i++) {

            if (ngModelObject[i].agGridDecode != null) {

                console.log(ngModelObject[i].agGridDecode);

                for (let j = 0; j < ngModelObject.length; j++) {

                    if (ngModelObject[i].agGridDecode == ngModelObject[j].keyName) {

                        console.log(ngModelObject[i].agGridDecode);

                        ngModelObject[j].keyValue = "";

                    }

                }

            }

        }*/

        //In temporaryObject storing jsons with needed keys.
        let temporaryObject: any = [];

        temporaryObject = [];

        ngModelObject.forEach(e => {

            console.log(e);

            let ngModelObjectLocal = { keyName: "", keyValue: "", binaryFormat: "", tableName: "", status: "", columnType: "", imageNameDisplay: "" };

            console.log(e.keyValue);

            if (e.columnType == "valueDate" || e.columnType == "valueStartDate" || e.columnType == "valueEndDate") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue != null) {

                    console.log(e.keyValue);

                    e.keyValue = moment(e.keyValue).format("YYYY-MM-DD");

                    console.log(ngModelObject[e.keyValue]);

                }

            }

            if (e.columnType == "valueDateTime" || e.columnType == "valueStartDateTime" || e.columnType == "valueEndDateTime") {

                console.log(e.keyValue);

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = "";

                }

                if (e.keyValue != "") {

                    console.log(e.keyValue);

                    var dateString = e.keyValue._d;

                    if (dateString == undefined) {

                    }

                    else {

                        console.log(dateString);

                        var dateObj = new Date(dateString);

                        console.log(dateObj);

                        var momentObj = moment(dateObj);

                        console.log(momentObj);

                        e.keyValue = momentObj.format('YYYY-MM-DD HH:mm:ss');

                    }

                    console.log(e.keyValue);

                }

            }

            if (e.columnType == "valueCheckbox") {

                console.log(e.keyValue);

                if (e.keyValue == "") {

                    e.keyValue = null;

                }

                if (e.keyValue == null) {

                    console.log(e.keyValue);

                    e.keyValue = false;

                    console.log(e.keyValue);

                }

            }

            ngModelObjectLocal.keyName = e.keyName;

            ngModelObjectLocal.keyValue = e.keyValue;

            ngModelObjectLocal.binaryFormat = e.binaryFormat;

            ngModelObjectLocal.columnType = e.columnType;

            ngModelObjectLocal.imageNameDisplay = e.imageNameDisplay;

            console.log(ngModelObjectLocal);

            temporaryObject.push(ngModelObjectLocal);

        });

        console.log(temporaryObject);

        return await temporaryObject;

    }

    async insertData(temporaryObject, tableName, isExecute, ngModelObject, tableID) {

        console.log(temporaryObject);

        console.log(tableName);

        console.log(isExecute);

        console.log(ngModelObject);

        if (isExecute == 0) {

            temporaryObject.forEach(tempObject => {

                if (tempObject.binaryFormat == true) {

                    tempObject.keyValue = tempObject.keyValue.split(",", 2)[1];

                    console.log(tempObject.keyValue);

                }

            });

            for (var i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject.keyValue != null) {

                    temporaryObject.keyValue = temporaryObject.keyValue.toString().trim();

                }

            }

            let idName = "";

            /*let agGridDecodeNames = [];

            console.log(agGridDecodeNames);*/

            idName = tableID;

            /*ngModelObject.forEach(ngModelObjectTemporary => {

                if (ngModelObjectTemporary["agGridDecode"] != null) {

                    agGridDecodeNames.push(ngModelObjectTemporary["agGridDecode"]);

                }

            });*/

            console.log(idName);

            //console.log(agGridDecodeNames);

            for (let i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject[i].keyName == idName) {

                    temporaryObject.splice(i, 1);

                }

            }

            console.log(temporaryObject);

            /*agGridDecodeNames.forEach(agGridDecodeNamesTemporary => {

                console.log(agGridDecodeNamesTemporary);

                console.log(temporaryObject);

                for (let i = 0; i < temporaryObject.length; i++) {

                    console.log(temporaryObject[i].keyName);

                    if (temporaryObject[i].keyName == agGridDecodeNamesTemporary) {

                        console.log(temporaryObject[i]);

                        temporaryObject.splice(i, 1);

                    }

                }

            });*/

            temporaryObject.forEach(tempObject => {

                if (tempObject.keyValue == "" || tempObject.keyValue == undefined) {

                    tempObject.keyValue = null;

                }

                //If the column type is valueCheckbox then the keyValue will be change to false.
                if (tempObject.columnType == "valueCheckbox") {

                    if (tempObject.keyValue == null) {

                        tempObject.keyValue = false;

                        console.log(tempObject.keyValue);

                    }

                }

            });

            temporaryObject[0].tableName = tableName;

            temporaryObject[0].status = 'Yes';

            console.log(temporaryObject);

            //Api call to insert data. It will generate response, it has value true or false.
            let response = await this.service.insertService(temporaryObject);

            return response;

        }

        else {

            this.snackBar.open("It will not allow duplicate records.", "OK", { duration: 5000 });

        }

    }

    async updateData(temporaryObject, tableName, isExecute, ngModelObject, tableID) {

        console.log(temporaryObject);

        console.log(tableName);

        console.log(isExecute);

        console.log(ngModelObject);

        console.log(tableID);

        temporaryObject.forEach(tempObject => {

            if (tempObject.binaryFormat == true) {

                tempObject.keyValue = tempObject.keyValue.split(",", 2)[1];

                console.log(tempObject.keyValue);

            }

        });

        for (var i = 0; i < temporaryObject.length; i++) {

            if (temporaryObject.keyValue != null) {

                temporaryObject.keyValue = temporaryObject.keyValue.toString().trim();

            }
        }

        let idName = "";

        /*let agGridDecodeNames = [];

        console.log(agGridDecodeNames);*/

        idName = tableID;

        console.log(idName);

        /*ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary["agGridDecode"] != null) {

                agGridDecodeNames.push(ngModelObjectTemporary["agGridDecode"]);

            }

        });*/

        console.log(idName);

        //console.log(agGridDecodeNames);

        if (isExecute <= 1) {

            let idValue: any;

            for (let i = 0; i < temporaryObject.length; i++) {

                if (temporaryObject[i].keyName == idName) {

                    idValue = temporaryObject[i].keyValue;

                    console.log(idValue);

                    //temporaryObject.splice(i, 1);

                }

            }

            console.log(temporaryObject);

            /*agGridDecodeNames.forEach(agGridDecodeNamesTemporary => {

                console.log(agGridDecodeNamesTemporary);

                console.log(temporaryObject);

                for (let i = 0; i < temporaryObject.length; i++) {

                    console.log(temporaryObject[i].keyName);

                    if (temporaryObject[i].keyName == agGridDecodeNamesTemporary) {

                        console.log(temporaryObject[i]);

                        temporaryObject.splice(i, 1);

                    }

                }

            });*/

            temporaryObject.forEach(tempObject => {

                if (tempObject.keyValue == "" || tempObject.keyValue == undefined) {

                    tempObject.keyValue = null;

                }

                //If the column type is valueCheckbox then the keyValue will be change to false.
                if (tempObject.columnType == "valueCheckbox") {

                    if (tempObject.keyValue == null) {

                        tempObject.keyValue = false;

                        console.log(tempObject.keyValue);

                    }

                }

            });

            console.log(temporaryObject);

            console.log(tableName);

            console.log(idName);

            console.log(idValue);

            //Api call to update data. It will generate response, it has value true or false.
            let response = await this.service.updateService(temporaryObject, tableName, idName, idValue);

            return response;

        }

        else {

            this.snackBar.open("It will not allow duplicate records.", "OK", { duration: 5000 });

        }

    }

    // event on input type file
    imageFunction($event, ngModelTemporary, ngModelObject, formValid): void {

        this.readThisImage($event.target, ngModelTemporary, ngModelObject, formValid);

    }

    // to encode the selected photo
    readThisImage(inputValue: any, ngModelTemporary, ngModelObject, formValid): void {

        console.log(inputValue);

        console.log(ngModelTemporary);

        var file: File = inputValue.files[0];

        console.log(file);

        var FileS: File = inputValue.files[0];

        console.log(FileS);

        console.log(file.type);

        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

            console.log(file.size / 1000)
            var size = file.size / 1000
            if (size <= 800) {
                var fileName = FileS.name;
                //var filename = (<HTMLInputElement>document.getElementById('file-id')).value;

                var photo: FileReader = new FileReader();

                console.log(typeof photo);

                photo.onloadend = e => {
                    var img = photo.result;
                    console.log(photo.result);
                    var photobase64result = photo.result.toString().split(",", 2)[1];

                    console.log(photobase64result);

                    // this is to check the file is empty or not
                    if (photobase64result == null) {
                        this.snackBar.open("Please Upload valid attachment", "OK", {
                            duration: 5000
                        });
                    } else {

                        ngModelTemporary.imageShow = true;

                        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                            ngModelTemporary.imageShow1 = true;

                            ngModelTemporary.binaryFormat = true;

                            ngModelTemporary.keyValue = photo.result.toString();

                        }

                        if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

                            ngModelTemporary.imageShow1 = false;

                            ngModelTemporary.fileShow = true;

                            ngModelTemporary.keyValue = photobase64result;

                        }

                        ngModelTemporary.downloadIconShow = false;

                        console.log(ngModelTemporary.keyValue);

                        ngModelObject.forEach(ngModelObjectTemporary => {

                            console.log(ngModelObjectTemporary.keyName);

                            console.log(ngModelTemporary.imageName);

                            if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                                ngModelObjectTemporary.keyValue = file.name;

                                ngModelTemporary.imageNameDisplay = file.name;

                            }

                        });

                    }
                };
                photo.readAsDataURL(file);
            } else {
                this.snackBar.open("Selected image " + file.name + ", size should be less than 800kb", "OK", {
                    duration: 5000,
                });

                ngModelTemporary.imageShow = false;

                ngModelTemporary.keyValue = "";

                formValid.get(ngModelTemporary.formControlValidationName).setValue(null);

                ngModelObject.forEach(ngModelObjectTemporary => {

                    console.log(ngModelObjectTemporary.keyName);

                    console.log(ngModelTemporary.imageName);

                    if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                        ngModelObjectTemporary.keyValue = "";

                        formValid.get(ngModelObjectTemporary.formControlValidationName).setValue(null);

                    }

                });

            }
        } else {
            this.snackBar.open(file.name + " is a invalid image type ", "OK", {
                duration: 5000,
            });

            ngModelTemporary.imageShow = false;

            ngModelTemporary.keyValue = "";

            formValid.get(ngModelTemporary.formControlValidationName).setValue(null);

            ngModelObject.forEach(ngModelObjectTemporary => {

                console.log(ngModelObjectTemporary.keyName);

                console.log(ngModelTemporary.imageName);

                if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                    ngModelObjectTemporary.keyValue = "";

                    formValid.get(ngModelObjectTemporary.formControlValidationName).setValue(null);

                }

            });

        }

    }

    openImage(ngModelTemporary) {

        console.log(ngModelTemporary)

        this.dialog.open(ImageviewPopupComponent, {
            width: "55%",
            height: "555px",
            data: { "ngModelTemporary": ngModelTemporary }
        });

    }

    //delete attachements
    deleteImage(ngModelTemporary, formValid, ngModelObject) {

        ngModelObject.forEach(ngModelObjectTemporary => {

            console.log(ngModelObjectTemporary.keyName);

            console.log(ngModelTemporary.imageName);

            if (ngModelObjectTemporary.keyName == ngModelTemporary.imageName) {

                ngModelTemporary.imageShow = false;

                ngModelTemporary.imageShow1 = false;

                ngModelTemporary.fileShow = false;

                ngModelTemporary.keyValue = "";

                ngModelTemporary.binaryFormat = false;

                ngModelTemporary.downloadIconShow = false;

                ngModelObjectTemporary.keyValue = "";

                formValid.get(ngModelObjectTemporary.formControlValidationName).setValue(null);

            }

        });

    }

    clearChildDropDown(ngModelObject, listObjectDropDown) {

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelObjectTemporary.dropDownIndex != null) {

                if (ngModelObjectTemporary.dropDownIndex != null) {

                    listObjectDropDown[ngModelObjectTemporary.childDropDownKey] = [];

                }

            }

        });

        return listObjectDropDown;

    }

    /*async filterFunction(event, listObjectDropDown, ngModelObject, dropDowns) {

        console.log(event);

        if (event.childDropDownKey != null) {

            if (event.keyValue != undefined) {

                let keyName = event.keyName;

                let keyValue = event.keyValue;

                let childDropDownKey = event.childDropDownKey;

                let tableName = "";

                console.log(childDropDownKey);

                ngModelObject.forEach(ngModelObjectTemporary => {

                    if (ngModelObjectTemporary.dropDownIndex == childDropDownKey) {

                        console.log(ngModelObjectTemporary);

                        tableName = ngModelObjectTemporary.tableName;

                        console.log(tableName);

                    }

                });

                console.log(keyName);

                console.log(keyValue);

                listObjectDropDown[childDropDownKey] = [];

                let response: any = [];

                response = await this.service.retrieveService(tableName);

                let responseArray: any = [];

                response.forEach(responseTemporary => {

                    if (event.keyValue == responseTemporary[keyName]) {

                        responseArray.push(responseTemporary);

                    }

                });

                console.log(dropDowns);

                let dropDown = dropDowns[childDropDownKey];

                console.log(dropDown);

                for (let i = 0; i < responseArray.length; i++) {

                    console.log(i);

                    console.log(responseArray[i][dropDown[3]]);

                    console.log(responseArray[i][dropDown[4]]);

                    responseArray[i]["keyJson"] = [responseArray[i][dropDown[3]]];

                    responseArray[i]["dropDownValueDisplay"] = responseArray[i][dropDown[4]];

                }

                listObjectDropDown[childDropDownKey] = response;

                console.log(listObjectDropDown[childDropDownKey]);

            }

        }

        console.log(listObjectDropDown);

        return await listObjectDropDown;

    }*/

    changeStartEndDateValue(ngModelTemporary, ngModelObject) {

        console.log(ngModelTemporary);

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelTemporary.keyName == ngModelObjectTemporary.startDateName) {

                ngModelObjectTemporary.startDateValue = ngModelTemporary.keyValue;

            }

            if (ngModelTemporary.keyName == ngModelObjectTemporary.endDateName) {

                ngModelObjectTemporary.endDateValue = ngModelTemporary.keyValue;

            }

        });

        console.log(ngModelObject);

    }

    changeStartEndDateTimeValue(ngModelTemporary, ngModelObject) {

        console.log(ngModelTemporary);

        ngModelObject.forEach(ngModelObjectTemporary => {

            if (ngModelTemporary.keyName == ngModelObjectTemporary.startDateTimeName) {

                ngModelObjectTemporary.startDateTimeValue = ngModelTemporary.keyValue;

            }

            if (ngModelTemporary.keyName == ngModelObjectTemporary.endDateTimeName) {

                ngModelObjectTemporary.endDateTimeValue = ngModelTemporary.keyValue;

            }

        });

        console.log(ngModelObject);

    }

    cleanStartEndDateValue(ngModelObject) {

        ngModelObject.forEach(ngModelObjectTemporary => {

            ngModelObjectTemporary.startDateValue = moment(null);

            ngModelObjectTemporary.endDateValue = moment(null);

        });

    }

    cleanStartEndDateTimeValue(ngModelObject) {

        ngModelObject.forEach(ngModelObjectTemporary => {

            ngModelObjectTemporary.startDateTimeValue = moment(null);

            ngModelObjectTemporary.endDateTimeValue = moment(null);

        });

    }

    checkingStatusSnackBar() {

        this.snackBar.open('This record has been already deleted', "OK", { duration: 5000 });

    }
    
    /*
  Author-Abhishek T
  date-09-05-2023
  desc-this method to check the extentions present in the file
*/
    hasMultipleExtensions(file: File): boolean {
        const fileName = file.name;
        const extensions = fileName.split('.').slice(1); // get all extensions except the first one
        return extensions.length > 1;
      }
      

}