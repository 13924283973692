import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RestApi } from "src/app/shared/RestApi/restApi";
import { Router } from '@angular/router';

interface commonServiceInfo {
  statusMessage: any;
  error: boolean;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ScreenCreatorTransactionService {

  private headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxUiLoaderService,
    private restAPI: RestApi
  ) { }

  async insertService(screenCreatorObject) {

    this.spinner.start();

    let response = await this.http.post<commonServiceInfo>(this.restAPI.Insert_Transaction_Screen_Creator_URL, JSON.stringify(screenCreatorObject), { headers: this.headers }).toPromise();

    console.log(response);
    this.spinner.stop();
    this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });
    return response.error;

  }

  async retrieveService() {

    this.spinner.start();

    console.log(this.restAPI.RETRIEVE_SCREEN_CREATOR_COMPLETE_DATA_URL);

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_SCREEN_CREATOR_COMPLETE_DATA_URL, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveScreenNamesForDropDown() {
    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_SCREENNAMES_FORDROPDOWN, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }
  }

  async retrieveAgGridService(screenType) {

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_SCREEN_CREATOR_TRANSACTION_MASTER_URL + "/" + screenType, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveTransactionService(screenId) {

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_SCREEN_CREATOR_TRANSACTION_CHILD_URL + "/" + screenId, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async updateStatus(screenName, status) {
    this.spinner.start();
    console.log(screenName)
    console.log(status)
    let response = await this.http.put<commonServiceInfo>(this.restAPI.UPDATE_SCREENCREATOR_URL + "/" + screenName + "/" + status, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {
      console.log(response)
      this.spinner.stop();
      return response;

    }
  }

  async retrieveTrnxScreenByTableNameService(tableName) {

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_TRNXSCREEN_BY_TABLE_NAME_URL + "/" + tableName, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveTrnxChildScreenByTableNameService(parentTableId) {
    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.RETRIEVE_TRNXCHILDSCREEN_BY_TABLE_ID_URL + "/" + parentTableId, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }
  }

  async retrieveFieldsService() {

    this.spinner.start();

    let response = await this.http.get<commonServiceInfo>(this.restAPI.Retrieve_Transaction_Screen_Creator_Fields_URL, { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async retrieveCommonService(httpurl) {

    this.spinner.start();

    console.log(httpurl);

    let response = await this.http.get<commonServiceInfo>(httpurl, { headers: this.headers }).toPromise();

    console.log(response);

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

      return null;

    }
    else {

      this.spinner.stop();

      return response;

    }

  }

  async deleteTableService(screenName) {

    this.spinner.start();

    let response = await this.http.delete<commonServiceInfo>(this.restAPI.DELETE_TABLE_URL + "/" + screenName + "/trxn", { headers: this.headers }).toPromise();

    if (response.error == true) {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 5000 });

    }
    else {

      this.spinner.stop();

      this.snackBar.open(response.statusMessage, "OK", { duration: 50000 });
    }

  }

}
